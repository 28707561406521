import React, { useState, useEffect } from 'react';
import { Menu } from "antd";
import logo from "../Assets/image/logo.jpg"
import {
    BugOutlined, DashboardOutlined,
    FolderOutlined, ProjectOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const SideBar = () => {
    const [isRootUser, setIsRootUser] = useState(false);

    useEffect(() => {
        const token = Cookies.get("token");
        if (token) {
            try {
                const decoded = jwtDecode(token);
                setIsRootUser(decoded.sub === 'root');
            } catch (error) {
                console.error('Error decoding token:', error);
                setIsRootUser(false);
            }
        }
    }, []);

    return (
        <>
            <div className="sidebar-container">
                <div className="logo-container">
                    <Link to="/">
                        <img className="logo" src={logo} alt="Logo"/>
                    </Link>
                </div>

                <Menu
                    mode="inline"
                    className="menu-bar"
                    theme="dark"
                    inlineCollapsed={false}
                >
                    {isRootUser ? (
                        // Menu items for root user
                        <>
                            <Menu.Item key="3" icon={<UserOutlined/>}>
                                <Link to="/user">Users</Link>
                            </Menu.Item>
                            <Menu.Item key="4" icon={<DashboardOutlined/>}>
                                <Link to="/dashboard">Dashboard</Link>
                            </Menu.Item>
                            <Menu.Item key="5" icon={<ProjectOutlined/>}>
                                <Link to="/list-project">List Project</Link>
                            </Menu.Item>
                        </>
                    ) : (
                        // Menu items for non-root users
                        <>
                            <Menu.Item key="1" icon={<FolderOutlined/>}>
                                <Link to="/">Projects</Link>
                            </Menu.Item>
                            <Menu.Item key="2" icon={<BugOutlined/>}>
                                <Link to="/defectIndividual">Defects</Link>
                            </Menu.Item>
                        </>
                    )}
                </Menu>
            </div>
        </>
    );
};

export default SideBar;
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import RepositoryDetail from "./Repository/RepositoryDetail";
import Home from "./Repository/Home";
import DefectIndividual from "./Defect/DefectIndividual";
import Login from "./Auth/Login";
import Profile from "./Profile/Profile";
import Branches from "./Repository/Branches";
import RepositoryCommits from "./Repository/RepositoryCommits";
import Members from "./Repository/Members";
import ActivityChartProject from "./Analytics/ActivityChartProject";
import ContributeChartProject from "./Analytics/ContributeChartProject";
import {AuthProvider} from "./Auth/AuthConext";
import ProtectedRoute from "./Auth/ProtectedRoute"
import ReviewCode from "./Repository/ReviewCode";
import CommitCode from "./Repository/CommitCode";
import Defects from "./Defect/Defects";
import UpdateDefect from "./Defect/UpdateDefect";
import DefectRepositoryDetail from "./Defect/DefectRepositoryDetail";
import Users from "./User/Users";
import UserDetail from "./User/UserDetail";
import ForgotPassword from "./Auth/ForgotPassword";
import RootProtectedRoute from "./Auth/RootProtectedRoute";
import NonRootProtectedRoute from "./Auth/NonRootProtectedRoute";
import ProjectDetails from "./Repository/ProjectDetails";
import Dashboard from "./User/Dashboard";
import DashboardDefect from "./Defect/DashboardDefect";
import ListProject from "./User/ListProject";

function App() {
    return (
        <>
            <AuthProvider>
                <Router>
                    <Routes>
                        {/* Public routes */}
                        <Route path={'/login'} element={<Login/>}/>
                        <Route path={'/forgot_password'} element={<ForgotPassword/>}/>

                        {/* Routes that require base authentication */}
                        <Route path={`/repository/:id/commit/code`}
                               element={<ProtectedRoute element={CommitCode} />}/>
                        <Route path={'/project/:projectName'} element={<ProtectedRoute element={ProjectDetails}/>}/>

                        <Route path={`/project/:projectName/repository/:repositoryName/defect/:defectId`}
                               element={<ProtectedRoute element={DefectRepositoryDetail} />}/>
                        <Route path={`/project/:projectName/repository/:repositoryName/defect`}
                               element={<ProtectedRoute element={Defects} />}/>

                        {/* Root-only routes */}
                        <Route path={'/user'}
                               element={<ProtectedRoute element={RootProtectedRoute} componentProps={{ element: Users }} />}/>
                        <Route path={'/dashboard'}
                               element={<ProtectedRoute element={RootProtectedRoute} componentProps={{ element: Dashboard }} />}/>
                        <Route path={'/user/:username'}
                               element={<ProtectedRoute element={RootProtectedRoute} componentProps={{ element: UserDetail }} />}/>
                        <Route path={'list-project'}
                               element={<ProtectedRoute element={RootProtectedRoute} componentProps={{ element: ListProject }} />}/>

                        {/* Non-root routes */}
                        <Route path={'/'}
                               element={<ProtectedRoute element={NonRootProtectedRoute} componentProps={{ element: Home }} />}/>
                        <Route path={'/edit-profile'}
                               element={<ProtectedRoute element={NonRootProtectedRoute} componentProps={{ element: Profile }} />}/>
                        <Route path={'/profile'}
                               element={<ProtectedRoute element={NonRootProtectedRoute} componentProps={{ element: Profile }} />}/>
                        <Route path={'/:host'}
                               element={<ProtectedRoute element={NonRootProtectedRoute} componentProps={{ element: Home }} />}/>
                        <Route path={'/project/:projectName/repository/:repositoryName'}
                               element={<ProtectedRoute element={NonRootProtectedRoute} componentProps={{ element: RepositoryDetail }} />}/>
                        <Route path={'/project/:projectName/repository/:repositoryName/tree/:selectedBranch/*'}
                               element={<ProtectedRoute element={NonRootProtectedRoute} componentProps={{ element: RepositoryDetail }} />}/>
                        <Route path={'/project/:projectName/repository/:repositoryName/blob/:selectedBranch/*'}
                               element={<ProtectedRoute element={NonRootProtectedRoute} componentProps={{ element: ReviewCode }} />}/>
                        <Route path={`/project/:projectName/repository/:repositoryName/branch`}
                               element={<ProtectedRoute element={NonRootProtectedRoute} componentProps={{ element: Branches }} />}/>
                        <Route path={`/projects/:projectName/repositories/:repositoryName/commits`}
                               element={<ProtectedRoute element={NonRootProtectedRoute} componentProps={{ element: RepositoryCommits }} />}/>
                        <Route path={`/project/:projectName/member`}
                               element={<ProtectedRoute element={NonRootProtectedRoute} componentProps={{ element: Members }} />}/>
                        <Route path={`/repository/:repositoryName/activity`}
                               element={<ProtectedRoute element={NonRootProtectedRoute} componentProps={{ element: ActivityChartProject }} />}/>
                        <Route path={`/repository/:repositoryName/contribute`}
                               element={<ProtectedRoute element={NonRootProtectedRoute} componentProps={{ element: ContributeChartProject }} />}/>
                        <Route path={'/defectIndividual'}
                               element={<ProtectedRoute element={NonRootProtectedRoute} componentProps={{ element: DefectIndividual }} />}/>
                        <Route path={`/repository/:id/defect/:defectId/update`}
                               element={<ProtectedRoute element={NonRootProtectedRoute} componentProps={{ element: UpdateDefect }} />}/>
                        <Route path={`/repository/:id/defect/:defectId/Dashboard`}
                               element={<ProtectedRoute element={NonRootProtectedRoute} componentProps={{ element: DashboardDefect }} />}/>
                    </Routes>
                </Router>
            </AuthProvider>
        </>
    )
}

export default App;
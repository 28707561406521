import React, {useEffect, useState} from "react";
import {Flex, Menu} from "antd";
import {Link, useParams, useLocation} from "react-router-dom";
import logo from "../Assets/image/logo.jpg"
import {
    AppstoreAddOutlined,
    BranchesOutlined,
    BugOutlined,
    FolderOutlined,
    HistoryOutlined,
    TeamOutlined,
    UserOutlined
} from "@ant-design/icons";
import Cookies from "js-cookie";
import {jwtDecode} from "jwt-decode";

function SiderBarDetail() {
    const { projectName, repositoryName } = useParams();
    const [isRootUser, setIsRootUser] = useState(false);
    const location = useLocation();

    // Check if we're in a repository context
    const isRepositoryContext = Boolean(repositoryName);

    useEffect(() => {
        const token = Cookies.get("token");
        if (token) {
            try {
                const decoded = jwtDecode(token);
                setIsRootUser(decoded.sub === 'root');
            } catch (error) {
                console.error('Error decoding token:', error);
                setIsRootUser(false);
            }
        }
    }, []);

    return(
        <>
            <div className="sidebar-container">
                <div className="logo-container">
                    <Link to="/">
                        <img className="logo" src={logo} alt="Logo"/>
                    </Link>
                </div>

                <Menu
                    mode="inline"
                    className="menu-bar"
                    theme="dark"
                    inlineCollapsed={false}
                >
                    <Menu.Item key="projects" icon={<FolderOutlined/>}>
                        <Link to="/">Projects</Link>
                    </Menu.Item>

                    <Menu.SubMenu key="manage" icon={<AppstoreAddOutlined/>} title="Manage">
                        <Menu.Item key="members" icon={<TeamOutlined/>}>
                            <Link to={`/project/${projectName}/member`}>Members</Link>
                        </Menu.Item>
                        {isRepositoryContext && (
                            <>
                                <Menu.Item key="commits" icon={<HistoryOutlined/>}>
                                    <Link to={`/projects/${projectName}/repositories/${repositoryName}/commits`}>Commits</Link>
                                </Menu.Item>
                                <Menu.Item key="branches" icon={<BranchesOutlined/>}>
                                    <Link to={`/project/${projectName}/repository/${repositoryName}/branch`}>Branches</Link>
                                </Menu.Item>
                            </>
                        )}
                        <Menu.Item key="defects" icon={<BugOutlined/>}>
                            <Link to={`/project/${projectName}/repository/${repositoryName}/defect`}>Defects</Link>
                        </Menu.Item>
                    </Menu.SubMenu>

                    <Menu.Item key="defect" icon={<BugOutlined/>}>
                        <Link to="/defectIndividual">Defect</Link>
                    </Menu.Item>

                    {isRootUser && (
                        <Menu.Item key="users" icon={<UserOutlined/>}>
                            <Link to="/user">Users</Link>
                        </Menu.Item>
                    )}
                </Menu>
            </div>
        </>
    )
}

export default SiderBarDetail;
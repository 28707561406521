import React from 'react';
import { Dropdown, Flex, Menu, Typography, message } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import Avatar from "antd/es/avatar/avatar";
import '../Assets/css/index.css';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Auth/AuthConext";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

function HeaderComponent() {
    const navigate = useNavigate();
    const { logout } = useAuth();

    const handleLogout = async () => {
        try {
            const token = Cookies.get("token");
            const response = await fetch('https://dxgitauto.xyz/api/v1/auth/logout', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                logout(); // Clear auth context
                Cookies.remove("token"); // Remove token
                message.success("Logged out successfully");
                navigate('/login');
            } else {
                const data = await response.json();
                message.error(data.error || "Logout failed");
            }
        } catch (error) {
            console.error('Logout error:', error);
            message.error("Failed to logout. Please try again.");
        }
    };

    const handleClickMenuAvatar = (e) => {
        if (e.key === '1') {
            navigate('/profile');
        }
    };

    const token = Cookies.get("token");
    let username = "";

    if (token) {
        const decoded = jwtDecode(token);
        username = decoded.sub;
    }

    const menu = (
        <Menu onClick={handleClickMenuAvatar}>
            <Menu.Item key={'1'}>
                <span className={'header-avatar-menu-icons'}>
                    <UserOutlined />
                </span>
                <span>Profile</span>
            </Menu.Item>
            <Menu.Item key={'2'}>
                <span className={'header-avatar-menu-icons'}>
                    <LogoutOutlined />
                </span>
                <span onClick={handleLogout}>Log Out</span>
            </Menu.Item>
        </Menu>
    );

    return (
        <Flex align={'center'} justify={'space-between'} className={'header'}>
            <Typography.Title level={2} className={'header-title'}>
                <span>{username}</span>
                <span className="header-wave"> 👋</span>
            </Typography.Title>
            <Dropdown overlay={menu} trigger={['click']}>
                <Avatar icon={<UserOutlined />} className={'header-avatar'} />
            </Dropdown>
        </Flex>
    );
}

export default HeaderComponent;
import React, { useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import LayoutComponentDetail from "../Layout/LayoutComponentDetail";
import '../Assets/css/dashboard.css'

function DashboardDefect() {
    const [statusData, setStatusData] = useState([
        { status: 'New', count: 40 },
        { status: 'In Progress', count: 25 },
        { status: 'Resolved', count: 15 },
        { status: 'Re Opened', count: 20 },
        { status: 'Close', count: 5 }
    ]);

    const [criticalData, setCriticalData] = useState([
        { status: 'Blocker', count: 30 },
        { status: 'Critical', count: 45 },
        { status: 'Major', count: 20 },
        { status: 'Minor', count: 15 }
    ]);

    const STATUS_COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];
    const CRITICAL_COLORS = ['#dc3545', '#ff6b6b', '#ffd93d', '#4dabf7'];

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
        const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);

        return (
            <text
                x={x}
                y={y}
                fill="white"
                textAnchor="middle"
                dominantBaseline="central"
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <LayoutComponentDetail>
            <div className="dashboard-container">
                <div className="charts-grid">
                    <div className="chart-item">
                        <h2 className="dashboard-title">Defect Status Distribution</h2>
                        <div className="chart-container">
                            <div className="chart-wrapper">
                                <PieChart width={500} height={400}>
                                    <Pie
                                        data={statusData}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        label={renderCustomizedLabel}
                                        outerRadius={130}
                                        fill="#8884d8"
                                        nameKey="status"
                                        dataKey="count"
                                    >
                                        {statusData.map((entry, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={STATUS_COLORS[index % STATUS_COLORS.length]}
                                            />
                                        ))}
                                    </Pie>
                                    <Tooltip
                                        formatter={(value, name, props) => [
                                            value,
                                            `${props.payload.status} Defects`
                                        ]}
                                    />
                                    <Legend
                                        formatter={(value, entry, index) => statusData[index].status}
                                    />
                                </PieChart>
                            </div>
                        </div>
                    </div>

                    <div className="chart-item">
                        <h2 className="dashboard-title">Defect Severity Distribution</h2>
                        <div className="chart-container">
                            <div className="chart-wrapper">
                                <PieChart width={500} height={400}>
                                    <Pie
                                        data={criticalData}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        label={renderCustomizedLabel}
                                        outerRadius={130}
                                        fill="#8884d8"
                                        nameKey="status"
                                        dataKey="count"
                                    >
                                        {criticalData.map((entry, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={CRITICAL_COLORS[index % CRITICAL_COLORS.length]}
                                            />
                                        ))}
                                    </Pie>
                                    <Tooltip
                                        formatter={(value, name, props) => [
                                            value,
                                            `${props.payload.status} Priority`
                                        ]}
                                    />
                                    <Legend
                                        formatter={(value, entry, index) => criticalData[index].status}
                                    />
                                </PieChart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutComponentDetail>
    );
}

export default DashboardDefect;
import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from "js-cookie";
import { useAuth } from "./AuthConext";
import { jwtDecode } from "jwt-decode";

const RootProtectedRoute = ({ element: Component }) => {
    const token = Cookies.get('token');

    try {
        const decoded = jwtDecode(token);
        if (decoded.sub !== 'root') {
            return <Navigate to="/" />;
        }
    } catch (error) {
        console.error('Error decoding token:', error);
        return <Navigate to="/login" />;
    }

    return <Component />;
};

export default RootProtectedRoute;
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Button,
    Col,
    Form,
    message,
    Modal,
    Row,
    Select,
    Table,
    Tag,
    Input
} from "antd";
import {
    DeleteOutlined,
    SortAscendingOutlined,
    SortDescendingOutlined
} from "@ant-design/icons";
import dayjs from "dayjs";
import api from '../Ultils/Api';
import LayoutComponentDetail from "../Layout/LayoutComponentDetail";

const { Option } = Select;
const { Search } = Input;

const Defects = () => {
    // Form instance
    const [form] = Form.useForm();
    const { repositoryName, projectName } = useParams();
    const navigate = useNavigate();

    // Basic states
    const [defects, setDefects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userRole, setUserRole] = useState('');

    // Search and sort states
    const [searchText, setSearchText] = useState('');
    const [sortOrder, setSortOrder] = useState('desc');
    const [sortField, setSortField] = useState('defect_creation_time');

    // Modal states
    const [isModalVisibleCreateDefect, setIsModalVisibleCreateDefect] = useState(false);
    const [isModalVisibleDeleteDefect, setIsModalVisibleDeleteDefect] = useState(false);
    const [defectToDelete, setDefectToDelete] = useState(null);

    // Branch states
    const [branches, setBranches] = useState([]);
    const [branchesLoading, setBranchesLoading] = useState(false);

    // Utility functions for tag colors
    const getStatusColor = (status) => {
        const colors = {
            NEW: 'blue',
            IN_PROGRESS: 'orange',
            RESOLVED: 'green',
            CLOSED: 'gray'
        };
        return colors[status] || 'default';
    };

    const getPriorityColor = (priority) => {
        const colors = {
            HIGH: 'red',
            MEDIUM: 'orange',
            LOW: 'green'
        };
        return colors[priority] || 'default';
    };

    const getSeverityColor = (severity) => {
        const colors = {
            CRITICAL: 'red',
            MAJOR: 'orange',
            MINOR: 'green'
        };
        return colors[severity] || 'default';
    };

    // Table columns configuration
    const columns = [
        {
            title: 'Title',
            dataIndex: 'defect_title',
            key: 'defect_title',
            sorter: (a, b) => a.defect_title.localeCompare(b.defect_title),
            render: (text, record) => (
                <a onClick={() => handleDefectClick(record.key)}>{text}</a>
            )
        },
        {
            title: 'Reporter',
            dataIndex: 'defect_reporter',
            key: 'defect_reporter',
            sorter: (a, b) => a.defect_reporter.localeCompare(b.defect_reporter)
        },
        {
            title: 'Project',
            dataIndex: 'defect_project',
            key: 'defect_project',
            sorter: (a, b) => a.defect_reporter.localeCompare(b.defect_reporter)
        },
        {
            title: 'Creation Time',
            dataIndex: 'defect_creation_time',
            key: 'defect_creation_time',
            sorter: (a, b) => dayjs(a.defect_creation_time).unix() - dayjs(b.defect_creation_time).unix(),
            render: (text) => dayjs(text).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: 'Assigned Person',
            dataIndex: 'defect_assigned_person',
            key: 'defect_assigned_person'
        },
        {
            title: 'Deadline',
            dataIndex: 'defect_deadline',
            key: 'defect_deadline',
            render: (text) => text ? dayjs(text).format('YYYY-MM-DD HH:mm:ss') : '-'
        },
        {
            title: 'Last Modified Time',
            dataIndex: 'defect_last_modified_time',
            key: 'defect_last_modified_time',
            render: (text) => dayjs(text).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: 'Priority',
            dataIndex: 'defect_priority',
            key: 'defect_priority',
            render: (priority) => (
                <Tag color={getPriorityColor(priority)}>{priority}</Tag>
            )
        },
        {
            title: 'Severity',
            dataIndex: 'defect_severity',
            key: 'defect_severity',
            render: (severity) => (
                <Tag color={getSeverityColor(severity)}>{severity}</Tag>
            )
        },
        {
            title: 'Status',
            dataIndex: 'defect_status',
            key: 'defect_status',
            render: (status) => (
                <Tag color={getStatusColor(status)}>{status}</Tag>
            )
        },
        {
            title: 'Approval Status',
            dataIndex: 'approval_status',
            key: 'approval_status',
            render: (status) => (
                <Tag color={getStatusColor(status)}>{status}</Tag>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Button
                    danger
                    onClick={() => handleShowDeleteDefectModal(record)}
                    icon={<DeleteOutlined />}
                />
            )
        }
    ];

    // API calls
    const fetchDefects = async () => {
        setLoading(true);
        try {
            const response = await api.get(`/defects/repository/${repositoryName}`);
            const formattedDefects = response.data.map(defect => ({
                key: defect.id,
                defect_title: defect.title,
                defect_reporter: defect.createdBy,
                defect_creation_time: defect.createdAt,
                defect_assigned_person: defect.assignedTo,
                defect_deadline: defect.deadline,
                defect_last_modified_time: defect.updatedAt,
                defect_priority: defect.priority,
                defect_severity: defect.severity,
                defect_status: defect.status,
                approval_status: defect.approval_status,
                defect_project: defect.projectName,
            }));
            setDefects(formattedDefects);
        } catch (error) {
            handleApiError(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchBranches = async () => {
        setBranchesLoading(true);
        try {
            const response = await api.get(
                `/git/project/${projectName}/repositories/${repositoryName}/branches`
            );
            if (response.data?.branches) {
                setBranches(response.data.branches.map(branch => branch.name));
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            setBranchesLoading(false);
        }
    };

    // Event handlers
    const handleCreateDefect = async () => {
        try {
            const values = await form.validateFields();
            const payload = {
                title: values.defectTitle,
                description: values.defectDescription,
                branchName: values.branch
            };


            await api.post(
                `/defects/project/${projectName}/repository/${repositoryName}`,
                payload
            );

            message.success('Defect created successfully');
            setIsModalVisibleCreateDefect(false);
            form.resetFields();
            fetchDefects();
        } catch (error) {
            if (!error.errorFields) {
                handleApiError(error);
            }
        }
    };

    const handleDeleteDefect = async () => {
        if (!defectToDelete) return;

        try {
            await api.delete(`/defects/${defectToDelete.key}`);
            message.success('Defect deleted successfully');
            setIsModalVisibleDeleteDefect(false);
            setDefectToDelete(null);
            fetchDefects();
        } catch (error) {
            handleApiError(error);
        }
    };

    const handleDefectClick = (defectId) => {
        navigate(`/project/${projectName}/repository/${repositoryName}/defect/${defectId}`);
    };

    const handleShowDeleteDefectModal = (defect) => {
        setDefectToDelete(defect);
        setIsModalVisibleDeleteDefect(true);
    };

    const handleApiError = (error) => {
        console.error('API Error:', error);
        if (error.response?.status === 401) {
            message.error('Session expired. Please login again.');
            navigate('/login');
        } else {
            message.error('Operation failed. Please try again.');
        }
    };

    // Data processing
    const getFilteredAndSortedData = () => {
        return defects
            .filter(defect => {
                const searchLower = searchText.toLowerCase();
                return Object.values(defect)
                    .filter(value => typeof value === 'string')
                    .some(value => value.toLowerCase().includes(searchLower));
            })
            .sort((a, b) => {
                let comparison = 0;
                if (sortField.includes('time') || sortField.includes('deadline')) {
                    comparison = dayjs(a[sortField]).diff(dayjs(b[sortField]));
                } else {
                    comparison = (a[sortField] || '').localeCompare(b[sortField] || '');
                }
                return sortOrder === 'asc' ? comparison : -comparison;
            });
    };

    // Effects
    useEffect(() => {
        if (repositoryName) {
            fetchDefects();
            fetchBranches();
        }
    }, [repositoryName]);

    // Render functions
    const renderCreateDefectModal = () => (
        <Modal
            title="Create Defect"
            open={isModalVisibleCreateDefect}
            onOk={handleCreateDefect}
            onCancel={() => {
                setIsModalVisibleCreateDefect(false);
                form.resetFields();
            }}
            okText="Create"
            cancelText="Cancel"
            okButtonProps={{ className: 'custom-ok-button' }}
            width={700}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    defectTitle: '',
                    defectDescription: '',
                    branch: undefined
                }}
            >
                <Form.Item
                    label="Title"
                    name="defectTitle"
                    rules={[{ required: true, message: "Please input the name of the defect" }]}
                >
                    <Input placeholder="Enter defect title" />
                </Form.Item>

                <Form.Item
                    label="Description"
                    name="defectDescription"
                    rules={[{ required: true, message: "Please input the description of the defect" }]}
                >
                    <Input.TextArea
                        placeholder="Enter defect description"
                        rows={4}
                    />
                </Form.Item>

                <Form.Item
                    label="Branch"
                    name="branch"
                    rules={[{ required: true, message: "Please select a branch" }]}
                >
                    <Select
                        placeholder="Select Branch"
                        loading={branchesLoading}
                    >
                        {branches.map((branch) => (
                            <Option key={branch} value={branch}>
                                {branch}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );

    const renderDeleteDefectModal = () => (
        <Modal
            title="Delete Defect"
            open={isModalVisibleDeleteDefect}
            onOk={handleDeleteDefect}
            onCancel={() => {
                setIsModalVisibleDeleteDefect(false);
                setDefectToDelete(null);
            }}
            okText="Delete"
            cancelText="Cancel"
            okButtonProps={{
                className: 'custom-ok-button',
                danger: true
            }}
        >
            {defectToDelete && (
                <p>Are you sure you want to delete <strong>{defectToDelete.defect_title}</strong>?</p>
            )}
        </Modal>
    );

    // If loading, show loading state
    if (loading) {
        return (
            <LayoutComponentDetail>
                <div>Loading...</div>
            </LayoutComponentDetail>
        );
    }

    // If user is guest, show restricted access message
    if (userRole === 'Guest') {
        return (
            <LayoutComponentDetail>
                <div className="content-title">
                    <h1>Defects of Repository {repositoryName}</h1>
                </div>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <h2>Access Restricted</h2>
                    <p>You don't have permission to view defects in this repository.</p>
                    <p>Please contact the repository administrator for access.</p>
                </div>
            </LayoutComponentDetail>
        );
    }

    // Main render
    return (
        <LayoutComponentDetail>
            <div className="content-title">
                <h1>Defects of Repository {repositoryName}</h1>
            </div>

            <Row gutter={16} className="search-sort-container">
                <Col span={18}>
                    <Search
                        placeholder="Search"
                        className="search"
                        allowClear
                        onSearch={setSearchText}
                        style={{ borderRadius: '25px', height: '40px', marginLeft: '5px' }}
                    />
                </Col>
                <Col span={4}>
                    <Select
                        value={sortField}
                        className="select-sort"
                        style={{ width: '100%' }}
                        onChange={setSortField}
                    >
                        {columns
                            .filter(col => col.sorter)
                            .map(col => (
                                <Option key={col.key} value={col.key}>
                                    {col.title}
                                </Option>
                            ))}
                    </Select>
                </Col>
                <Col span={2}>
                    <Button
                        type="text"
                        icon={sortOrder === 'asc' ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
                        onClick={() => setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc')}
                        className="sort-btn"
                        style={{ width: '78%' }}
                    />
                </Col>
            </Row>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20px', marginTop: '10px' }}>
                <Button onClick={() => setIsModalVisibleCreateDefect(true)}>
                    Create Defect
                </Button>
            </div>

            <div className="defect-list">
                <Table
                    columns={columns}
                    dataSource={getFilteredAndSortedData()}
                    loading={loading}
                    pagination={{
                        pageSize: 10,
                        showTotal: (total) => `Total ${total} defects`,
                        showSizeChanger: true,
                        showQuickJumper: true
                    }}
                />
                {renderCreateDefectModal()}
                {renderDeleteDefectModal()}
            </div>
        </LayoutComponentDetail>
    );
};

export default Defects;
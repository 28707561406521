import React, { useState, useEffect } from 'react';
import { Card, Select, message } from 'antd';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import api from '../Ultils/Api';
import { useNavigate } from 'react-router-dom';

const ProjectChart = () => {
    const [selectedYear, setSelectedYear] = useState('2024');
    const [loading, setLoading] = useState(true);
    const [projectData, setProjectData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        fetchProjectData();
    }, []);

    const fetchProjectData = async () => {
        try {
            setLoading(true);
            const response = await api.get('/statistics/projects/monthly');

            if (response.data?.status === 200) {
                const dataByYear = transformData(response.data.data);
                setProjectData(dataByYear);

                // Set initial year to the most recent year
                const years = Object.keys(dataByYear);
                if (years.length > 0) {
                    setSelectedYear(years[years.length - 1]);
                }
            }
        } catch (error) {
            if (error.response?.status === 401) {
                message.error("Please log in again");
                navigate('/login');
            } else {
                message.error("Failed to fetch project data");
            }
        } finally {
            setLoading(false);
        }
    };

    const transformData = (data) => {
        const dataByYear = {};
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        // Initialize all months for each year with zero values
        data.forEach(item => {
            const [year] = item.month.split('-');
            if (!dataByYear[year]) {
                dataByYear[year] = monthNames.map(month => ({
                    month,
                    projects: 0,
                    newProjects: 0
                }));
            }
        });

        // Fill in actual values
        data.forEach(item => {
            const [year, month] = item.month.split('-');
            const monthIndex = parseInt(month) - 1;

            dataByYear[year][monthIndex] = {
                month: monthNames[monthIndex],
                projects: item.totalProjects,
                newProjects: item.newProjects
            };
        });

        return dataByYear;
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{
                    backgroundColor: 'white',
                    padding: '10px',
                    border: '1px solid #ccc'
                }}>
                    <p><strong>{label}</strong></p>
                    <p>Total Projects: {payload[0].value.toLocaleString()}</p>
                    <p>New Projects: {payload[0].payload.newProjects.toLocaleString()}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <Card
            style={{ marginTop: 24 }}
            loading={loading}
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>Project Statistics</span>
                    <Select
                        value={selectedYear}
                        style={{ width: 120 }}
                        onChange={setSelectedYear}
                        options={Object.keys(projectData).sort().map(year => ({
                            value: year,
                            label: year
                        }))}
                    />
                </div>
            }
        >
            <div style={{ width: '100%', height: 400 }}>
                <ResponsiveContainer>
                    <BarChart
                        data={projectData[selectedYear] || []}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        barSize={45}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis
                            label={{
                                value: 'Number of Projects',
                                angle: -90,
                                position: 'insideLeft'
                            }}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <Bar
                            dataKey="projects"
                            fill="#1890ff"
                            name="Total Projects"
                            radius={[4, 4, 0, 0]}
                            activeBar={{ fill: '#f5aa2c' }}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </Card>
    );
};

export  default ProjectChart
import React, { useEffect, useState } from "react";
import {
    Button, Col, Row, Select, message, List, Card, Space, Typography, Form, Modal, Input
} from "antd";
import {
    SortAscendingOutlined,
    SortDescendingOutlined,
    FolderOutlined,
    UserOutlined,
    ClockCircleOutlined,
    CloudServerOutlined,
    RollbackOutlined
} from "@ant-design/icons";

import Search from "antd/es/input/Search";
import { useNavigate } from "react-router-dom";
import LayoutComponent from "../Layout/LayoutComponent";
import api from "../Ultils/Api";

const { Option } = Select;
const { Text, Title } = Typography;

function Home() {
    // Basic states
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortField, setSortField] = useState('name');
    const [searchTerm, setSearchTerm] = useState('');
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [serverList, setServerList] = useState([]);

    // Project creation states
    const [isProjectModalVisible, setIsProjectModalVisible] = useState(false);
    const [form] = Form.useForm();

    // Server connection states
    const [isServerModalVisible, setIsServerModalVisible] = useState(false);
    const [serverForm] = Form.useForm();
    const [serverLoading, setServerLoading] = useState(false);
    const [isConnected, setIsConnected] = useState(false);

    const navigate = useNavigate();

    // Fetch projects and server list on component mount
    useEffect(() => {
        fetchProjects();
        fetchServerList();
    }, []);

    // Error handling
    const handleError = (error) => {
        console.error("Error:", error);
        if (error.response) {
            const status = error.response.status;
            switch (status) {
                case 401:
                    message.error("Please log in again");
                    navigate('/login');
                    break;
                case 403:
                    message.error("You don't have permission to access these projects");
                    break;
                case 400:
                    message.error(error.response.data?.message || "Invalid request");
                    break;
                default:
                    message.error(error.response.data?.message || "An error occurred");
            }
        } else {
            message.error("Network error. Please check your connection.");
        }
    };

    // Fetch server list
    const fetchServerList = async () => {
        try {
            const response = await api.get('/servers/list');
            if (response.data?.servers) {
                setServerList(response.data.servers);
            }
        } catch (error) {
            handleError(error);
        }
    };

    // Server Connection Handlers
    const handleConnectServer = async () => {
        try {
            setServerLoading(true);
            const values = await serverForm.validateFields();

            const connectResponse = await api.post('/servers/connect-by-display', null, {
                params: {
                    displayName: values.displayName,
                    serverUsername: values.username,
                    password: values.password
                }
            });

            if (connectResponse.data?.message) {
                message.success('Successfully connected to server');
                setIsServerModalVisible(false);
                serverForm.resetFields();
                setIsConnected(true);
                // Refresh projects after connecting
                fetchProjects();
            }
        } catch (error) {
            console.error('Error:', error);
            message.error(error.response?.data?.message || 'Failed to connect to server');
        } finally {
            setServerLoading(false);
        }
    };

    const handleSwitchToMain = async () => {
        try {
            setLoading(true);
            const response = await api.post('/git/switch-to-main');

            if (response.data) {
                message.success('Successfully switched to main server');
                // Refresh projects after switching back to main
                fetchProjects();
                setIsConnected(false);
            }
        } catch (error) {
            console.error('Error:', error);
            message.error(error.response?.data?.message || 'Failed to switch to main server');
        } finally {
            setLoading(false);
        }
    };

    const fetchProjects = async () => {
        setLoading(true);
        try {
            const response = await api.get('/projects');
            console.log('Projects response:', response.data); // Add this line

            if (response.data?.status === 200) {
                const projectsList = response.data.data || [];
                console.log('Projects list:', projectsList); // Add this line
                setProjects(projectsList);
            } else {
                throw new Error(response.data?.message || "Failed to fetch projects");
            }
        } catch (error) {
            console.error('Error fetching projects:', error); // Add this line
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleCreateProject = async () => {
        try {
            await form.validateFields();
            const values = form.getFieldsValue();

            if (!values.projectName.trim()) {
                message.error('Project name cannot be empty');
                return;
            }

            const response = await api.post('/projects', {
                projectName: values.projectName.trim(),
                description: values.description?.trim() || ""
            });

            if (response.data?.status === 200) {
                message.success('Project created successfully');
                setIsProjectModalVisible(false);
                form.resetFields();
                fetchProjects(); // Refresh project list
            } else {
                throw new Error(response.data?.message || "Failed to create project");
            }
        } catch (error) {
            if (error.errorFields) {
                // Form validation error
                return;
            }
            handleError(error);
        }
    };

    // UI handlers
    const handleProjectClick = (projectName) => {
        navigate(`/project/${projectName}`);
    };

    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    const handleSortChange = (value) => {
        setSortField(value);
    };

    const toggleSortOrder = () => {
        setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
    };

    // Filter and sort projects
    const sortedAndFilteredProjects = projects
        .filter(project => project.name.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => {
            const fieldA = a[sortField];
            const fieldB = b[sortField];

            if (fieldA < fieldB) return sortOrder === 'asc' ? -1 : 1;
            if (fieldA > fieldB) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });

    return (
        <LayoutComponent>
            <div className="home-page-content">
                <div className="content-title">
                    <h1>Your Projects</h1>
                </div>
                <Row gutter={16} className="search-sort-container" style={{ display: 'flex', alignItems: 'center' }}>
                    <Col span={14}>
                        <Search
                            placeholder="Search Projects"
                            allowClear
                            onSearch={handleSearch}
                            style={{width: '98%', marginLeft: '20px'}}
                        />
                    </Col>
                    <Col span={4}>
                        <Button
                            onClick={() => setIsServerModalVisible(true)}
                            style={{ width: '100%' }}
                        >
                            <Space>
                                <CloudServerOutlined/>
                                Connect Server
                            </Space>
                        </Button>
                    </Col>
                    <Col span={4}>
                        <Select
                            defaultValue="name"
                            style={{width: '100%'}}
                            onChange={handleSortChange}
                        >
                            <Option value="name">Name</Option>
                            <Option value="ownerUsername">Owner</Option>
                            <Option value="createdAt">Created At</Option>
                        </Select>
                    </Col>
                    <Col span={2}>
                        <Button
                            type="text"
                            icon={sortOrder === 'asc' ? <SortAscendingOutlined/> : <SortDescendingOutlined/>}
                            className="sort-btn"
                            onClick={toggleSortOrder}
                            style={{width: '83%'}}
                        />
                    </Col>
                </Row>
            </div>

            <div className="home-action-buttons" style={{ display: 'flex', justifyContent: 'flex-end', padding: '0 24px', gap: '8px' }}>
                <Button
                    onClick={() => setIsProjectModalVisible(true)}
                    className="custom-ok-button"
                >
                    Create Project
                </Button>

                {isConnected && (
                    <Button
                        onClick={handleSwitchToMain}
                        className="custom-ok-button"
                        icon={<RollbackOutlined />}
                    >
                        Switch to Main
                    </Button>
                )}
            </div>

            {/* Server Connection Modal */}
            <Modal
                title="Connect Server"
                open={isServerModalVisible}
                onOk={handleConnectServer}
                onCancel={() => {
                    setIsServerModalVisible(false);
                    serverForm.resetFields();
                }}
                okText="Connect"
                cancelText="Cancel"
                confirmLoading={serverLoading}
                okButtonProps={{className: 'custom-ok-button'}}
            >
                <Form
                    form={serverForm}
                    layout="vertical"
                >
                    <Form.Item
                        name="displayName"
                        label="Server"
                        rules={[{ required: true, message: 'Please select a server' }]}
                    >
                        <Select placeholder="Select a server">
                            {serverList.map(server => (
                                <Option key={server.displayName} value={server.displayName}>
                                    {server.displayName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="username"
                        label="Server Username"
                        rules={[
                            { required: true, message: 'Please input the server username' },
                            { whitespace: true, message: 'Username cannot be empty' }
                        ]}
                    >
                        <Input placeholder="Server Username" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[{ required: true, message: 'Please input your password' }]}
                    >
                        <Input.Password placeholder="Password" />
                    </Form.Item>
                </Form>
            </Modal>

            {/* Create Project Modal */}
            <Modal
                title="Create Project"
                open={isProjectModalVisible}
                onOk={handleCreateProject}
                onCancel={() => {
                    setIsProjectModalVisible(false);
                    form.resetFields();
                }}
                okText="Create"
                cancelText="Cancel"
                okButtonProps={{className: 'custom-ok-button'}}
            >
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Form.Item
                        label="Project Name"
                        name="projectName"
                        rules={[
                            {required: true, message: "Please input the name of the project"},
                            {min: 3, message: "Project name must be at least 3 characters"}
                        ]}
                    >
                        <Input placeholder="Project Name" />
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[{required: true, message: "Please input the description of the project"}]}
                    >
                        <Input.TextArea
                            placeholder="Description"
                            rows={4}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            <div className="repository-list-container" style={{padding: '24px'}}>
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 3,
                        xl: 4,
                        xxl: 4,
                    }}
                    dataSource={sortedAndFilteredProjects}
                    loading={loading}
                    renderItem={(project) => (
                        <List.Item>
                            <Card
                                hoverable
                                onClick={() => handleProjectClick(project.name)}
                                cover={
                                    <div style={{
                                        height: 120,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: '#f0f2f5'
                                    }}>
                                        <FolderOutlined style={{fontSize: 48, color: 'orangered'}}/>
                                    </div>
                                }
                            >
                                <Card.Meta
                                    title={<Title level={4}>{project.name}</Title>}
                                    description={
                                        <Space direction="vertical" size="small">
                                            <Space>
                                                <UserOutlined/>
                                                <Text type="secondary">Owner: {project.ownerUsername}</Text>
                                            </Space>
                                            <Space>
                                                <ClockCircleOutlined/>
                                                <Text type="secondary">
                                                    {new Date(project.createdAt).toLocaleDateString()}
                                                </Text>
                                            </Space>
                                            <Text type="secondary">{project.description}</Text>
                                            {project.serverHost && (
                                                <Text type="secondary">Server: {project.displayHost || project.serverHost}</Text>
                                            )}
                                        </Space>
                                    }
                                />
                            </Card>
                        </List.Item>
                    )}
                />
            </div>
        </LayoutComponent>
    );
}

export default Home;
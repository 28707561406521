import React, { useState, useEffect, useRef } from "react";
import LayoutComponentDetail from "../Layout/LayoutComponentDetail";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Table, Tabs, Card, message, Select, DatePicker, Input, Form, Space, Typography, Modal } from "antd";
import DefectComment from "./DefectComment";
import DefectActivityStream from "./DefectActivityStream";
import DefectSolution from "./DefectSolution";
import api from '../Ultils/Api';
import dayjs from 'dayjs';
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

const ROLES = {
    OWNER: 'owner',
    TESTER: 'tester',
    DEV: 'dev',
    GUEST: 'guest'
};

const statusOptions = ['NEW', 'CLOSED', 'IN_PROGRESS', 'RESOLVED', 'REOPENED'];
const priorityOptions = ['URGENT', 'HIGH', 'LOW', 'MEDIUM'];
const severityOptions = ['MAJOR', 'BLOCKER', 'MINOR', 'CRITICAL'];

function DefectRepositoryDetail() {
    // ... (các state và hooks giống như trước)
    const { host, repositoryName, defectId, projectName } = useParams();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('defect_comment');
    const [defectDetail, setDefectDetail] = useState(null);
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const [users, setUsers] = useState([]);
    const [form] = Form.useForm();
    const activityStreamRef = useRef();
    const [isOwner, setIsOwner] = useState(false);
    const [userRole, setUserRole] = useState('');
    const [approvalModalVisible, setApprovalModalVisible] = useState(false);
    const [approvalForm] = Form.useForm();

    // Permission check functions
    const hasViewPermission = () => {
        return userRole !== ROLES.GUEST;
    };

    const canAddComments = () => {
        return userRole !== ROLES.GUEST;
    };

    const canAddSolutions = () => {
        // Cho phép cả DEV và OWNER vào tab Solution
        return userRole === ROLES.DEV || userRole === ROLES.OWNER;
    };

    const DEFECT_STATUS = {
        PENDING: 'PENDING',
        APPROVED: 'APPROVED',
        REJECTED: 'REJECTED'
    };

    const hasEditPermission = () => {
        return (userRole === ROLES.OWNER || userRole === ROLES.TESTER) &&
            defectDetail?.status === DEFECT_STATUS.APPROVED;
    };
    
    const hasApprovalPermission = () => {
        return userRole === ROLES.TESTER; // Changed: Only testers can approve
    };

    const hasStatusChangePermission = () => {
        return (userRole === ROLES.OWNER || userRole === ROLES.TESTER) &&
            defectDetail?.status === DEFECT_STATUS.APPROVED;
    };

    const hasAssigneeChangePermission = () => {
        return userRole === ROLES.OWNER && // Changed: Only owners can assign
            defectDetail?.status === DEFECT_STATUS.APPROVED;
    };

    // Effects
    useEffect(() => {
        if (hasViewPermission()) {
            fetchDefectDetail();
            fetchUsers();
        }
    }, [defectId, repositoryName, userRole]);

    useEffect(() => {
        if (activeTab === 'defect_activity_stream' && activityStreamRef.current) {
            activityStreamRef.current.fetchActivities();
        }
    }, [defectDetail, activeTab]);

    // API Error Handler
    const handleApiError = (error) => {
        console.error('API Error:', error);
        if (error.response?.status === 401) {
            message.error('Session expired. Please login again.');
            navigate('/login');
        } else {
            message.error(error.response?.data?.message || 'Operation failed. Please try again.');
        }
    };


    // API Calls
    const fetchUsers = async () => {
        try {
            const token = Cookies.get("token");
            if (!token) {
                console.error('Token not found');
                return;
            }

            const decoded = jwtDecode(token);
            const username = decoded.sub;

            const membersResponse = await api.get(`/projects/${projectName}/members`);
            if (membersResponse.data?.data) {
                setUsers(membersResponse.data.data);
            }

            const roleResponse = await api.get(`/projects/${projectName}/members/role`, {
                params: { username }
            });

            if (roleResponse.data?.data) {
                const { owner, memberRole } = roleResponse.data.data;
                setIsOwner(owner);
                // Nếu là owner, set userRole là 'owner', ngược lại dùng memberRole
                setUserRole(owner ? ROLES.OWNER : memberRole);
            }
        } catch (error) {
            handleApiError(error);
        }
    };

    const fetchDefectDetail = async () => {
        setLoading(true);
        try {
            const response = await api.get(`/defects/${defectId}`);
            const defect = response.data.defect;

            const defectData = {
                key: defect.id,
                defect_title: defect.title,
                defect_reporter: defect.createdBy,
                defect_description: defect.description,
                defect_severity: defect.severity,
                defect_priority: defect.priority,
                defect_deadline: defect.deadline ? dayjs(defect.deadline) : null,
                defect_assigned_person: defect.assignedTo,
                defect_status: defect.status,
                defect_file_path: defect.filePath || '',
                defect_created_at: defect.createdAt,
                defect_updated_at: defect.updatedAt,
                status: defect.approval_status,  // Thêm trường status này
                approved_by: defect.approvedBy,
                approval_date: defect.approvalDate
            };

            setDefectDetail(defectData);
            form.setFieldsValue({
                ...defectData,
                defect_deadline: defectData.defect_deadline
            });
        } catch (error) {
            handleApiError(error);
        } finally {
            setLoading(false);
        }
    };
    // Event Handlers
    const handleStatusChange = async (status) => {
        if (!hasStatusChangePermission()) {
            message.warning('You do not have permission to change the status');
            return;
        }

        try {
            await api.put(`/defects/${defectId}/status`, { status });
            await fetchDefectDetail();
            if (activityStreamRef.current) {
                await activityStreamRef.current.fetchActivities();
            }
            message.success('Status updated successfully');
        } catch (error) {
            handleApiError(error);
        }
    };

    const handleAssigneeChange = async (assignee) => {
        if (!hasAssigneeChangePermission()) {
            message.warning('You do not have permission to change the assignee');
            return;
        }

        try {
            await api.put(`/defects/${defectId}/assign`, { assignee });
            await fetchDefectDetail();
            if (activityStreamRef.current) {
                await activityStreamRef.current.fetchActivities();
            }
            message.success('Defect assigned successfully');
        } catch (error) {
            handleApiError(error);
        }
    };

    const handleSaveChanges = async () => {
        if (!hasEditPermission()) {
            message.warning('You do not have permission to edit defect details');
            return;
        }

        try {
            const values = await form.validateFields();
            const updatedData = {
                severity: values.defect_severity,
                priority: values.defect_priority,
                filePath: values.defect_file_path,
                deadline: values.defect_deadline ? dayjs(values.defect_deadline).format('YYYY-MM-DD[T]HH:mm:ss') : null
            };

            await api.put(`/defects/${defectId}`, updatedData);
            setEditing(false);
            await fetchDefectDetail();
            if (activityStreamRef.current) {
                await activityStreamRef.current.fetchActivities();
            }
            message.success('Defect updated successfully');
        } catch (error) {
            if (error.errorFields) {
                message.error('Please check your input fields');
            } else {
                handleApiError(error);
            }
        }
    };

    const handleApproval = async () => {
        if (!hasApprovalPermission()) {
            message.warning('Only testers can approve defects'); // Updated message
            return;
        }

        try {
            const values = await approvalForm.validateFields();
            const approvalRequest = {
                approved: values.approved,
                comment: values.comment,
                priority: values.priority,
                severity: values.severity,
                assignedTo: values.assignedTo,
                dateline: values.dateline ? dayjs(values.dateline).format('YYYY-MM-DD[T]HH:mm:ss') : null
            };

            await api.put(`/defects/${defectId}/approve`, approvalRequest);
            setApprovalModalVisible(false);
            await fetchDefectDetail();
            if (activityStreamRef.current) {
                await activityStreamRef.current.fetchActivities();
            }
            message.success(values.approved ? 'Defect approved successfully' : 'Defect rejected');
        } catch (error) {
            handleApiError(error);
        }
    };

    const validateDeadline = {
        disabledDate: (current) => {
            return current && current < dayjs().startOf('day');
        },
        disabledTime: (current) => {
            if (current && dayjs(current).isSame(dayjs(), 'day')) {
                const currentHour = dayjs().hour();
                const currentMinute = dayjs().minute();

                return {
                    disabledHours: () => Array.from({ length: currentHour }, (_, i) => i),
                    disabledMinutes: (selectedHour) => {
                        if (selectedHour === currentHour) {
                            // Disable all minutes up to current minute + 30
                            return Array.from(
                                { length: Math.min(currentMinute + 30, 60) },
                                (_, i) => i
                            );
                        }
                        return [];
                    }
                };
            }
            return {};
        }
    };

// Custom validator for Form.Item
    const deadlineValidator = (_, value) => {
        if (!value) {
            return Promise.reject(new Error('Deadline is required'));
        }

        const minimumTime = dayjs().add(30, 'minutes');
        if (value.isBefore(minimumTime)) {
            return Promise.reject(
                new Error('Deadline must be at least 30 minutes from now')
            );
        }

        return Promise.resolve();
    };


    // Table Columns Configuration
    const columns = [
        {
            title: 'Severity',
            dataIndex: 'defect_severity',
            key: 'defect_severity',
            render: (text) => editing ? (
                <Form.Item
                    name="defect_severity"
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: 'Severity is required' }]}
                >
                    <Select
                        style={{ width: 130 }}
                        disabled={!hasEditPermission()}
                    >
                        {severityOptions.map(option => (
                            <Option key={option} value={option}>{option}</Option>
                        ))}
                    </Select>
                </Form.Item>
            ) : (text || '-')
        },
        {
            title: 'Priority',
            dataIndex: 'defect_priority',
            key: 'defect_priority',
            render: (text) => editing ? (
                <Form.Item
                    name="defect_priority"
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: 'Priority is required' }]}
                >
                    <Select
                        style={{ width: 130 }}
                        disabled={!hasEditPermission()}
                    >
                        {priorityOptions.map(option => (
                            <Option key={option} value={option}>{option}</Option>
                        ))}
                    </Select>
                </Form.Item>
            ) : (text || '-')
        },
        {
            title: 'Status',
            dataIndex: 'defect_status',
            key: 'defect_status',
            render: (text) => (
                <Select
                    value={text}
                    onChange={handleStatusChange}
                    style={{ width: 130 }}
                    disabled={!hasStatusChangePermission()}
                >
                    {statusOptions.map(status => (
                        <Option key={status} value={status}>{status}</Option>
                    ))}
                </Select>
            )
        },
        {
            title: 'Deadline',
            dataIndex: 'defect_deadline',
            key: 'defect_deadline',
            render: (text, record) => editing ? (
                <Form.Item
                    name="defect_deadline"
                    style={{ margin: 0 }}
                    rules={[
                        { validator: deadlineValidator }
                    ]}
                >
                    <DatePicker
                        showTime={{ format: 'HH:mm' }}
                        format="YYYY-MM-DD HH:mm"
                        disabledDate={validateDeadline.disabledDate}
                        disabledTime={validateDeadline.disabledTime}
                        disabled={!hasEditPermission()}
                        showNow={false}
                    />
                </Form.Item>
            ) : (record.defect_deadline ? record.defect_deadline.format('YYYY-MM-DD HH:mm') : '-')
        },
        {
            title: 'Assigned Person',
            dataIndex: 'defect_assigned_person',
            key: 'defect_assigned_person',
            render: (text) => (
                <Select
                    value={text || undefined}
                    onChange={handleAssigneeChange}
                    style={{ width: 160 }}
                    placeholder="Select assignee"
                    disabled={!hasAssigneeChangePermission()}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                >
                    {users.map(user => (
                        <Option key={user.userId} value={user.username}>{user.username}</Option>
                    ))}
                </Select>
            )
        }
    ];

    // Modal Component
    const ApprovalModal = () => (
        <Modal
            title="Defect Approval"
            open={approvalModalVisible}
            onCancel={() => setApprovalModalVisible(false)}
            footer={[
                <Button key="cancel" onClick={() => setApprovalModalVisible(false)}>
                    Cancel
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    onClick={handleApproval}
                    className="custom-ok-button"
                >
                    Submit
                </Button>
            ]}
            width={600}
        >
            <Form
                form={approvalForm}
                layout="vertical"
                initialValues={{ approved: true }}
            >
                <Form.Item
                    name="approved"
                    label="Approval Decision"
                    rules={[{ required: true }]}
                >
                    <Select>
                        <Option value={true}>Approve</Option>
                        <Option value={false}>Reject</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="severity"
                    label="Severity"
                    rules={[{ required: true }]}
                >
                    <Select>
                        {severityOptions.map(option => (
                            <Option key={option} value={option}>{option}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="priority"
                    label="Priority"
                    rules={[{ required: true }]}
                >
                    <Select>
                        {priorityOptions.map(option => (
                            <Option key={option} value={option}>{option}</Option>
                        ))}
                    </Select>
                </Form.Item>

                {/*<Form.Item*/}
                {/*    name="assignedTo"*/}
                {/*    label="Assign To"*/}
                {/*    rules={[{ required: true }]}*/}
                {/*>*/}
                {/*    <Select>*/}
                {/*        {users.map(user => (*/}
                {/*            <Option key={user.userId} value={user.username}>{user.username}</Option>*/}
                {/*        ))}*/}
                {/*    </Select>*/}
                {/*</Form.Item>*/}

                <Form.Item
                    name="dateline"
                    label="Deadline"
                    rules={[
                        { validator: deadlineValidator }
                    ]}
                >
                    <DatePicker
                        showTime={{ format: 'HH:mm' }}
                        format="YYYY-MM-DD HH:mm"
                        style={{ width: '100%' }}
                        disabledDate={validateDeadline.disabledDate}
                        disabledTime={validateDeadline.disabledTime}
                        showNow={false}
                    />
                </Form.Item>


                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.approved !== currentValues.approved}
                >
                    {({ getFieldValue }) =>
                        getFieldValue('approved') === false && (
                            <Form.Item
                                name="comment"
                                label="Rejection Reason"
                                rules={[{ required: true, message: 'Please provide rejection reason' }]}
                            >
                                <TextArea rows={4} />
                            </Form.Item>
                        )
                    }
                </Form.Item>
            </Form>
        </Modal>
    );

    // Early return for unauthorized users
    if (!hasViewPermission()) {
        return (
            <LayoutComponentDetail>
                <div style={{ padding: '24px' }}>
                    <Text type="warning">
                        You don't have permission to view defect details
                    </Text>
                </div>
            </LayoutComponentDetail>
        );
    }

    // Main render
    return (
        <LayoutComponentDetail>
            <div style={{ padding: '24px' }}>
                {/* Header Section */}
                <div style={{ marginBottom: '30px', marginLeft: '5px' }}>
                    <h1 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '8px' }}>
                        {defectDetail?.defect_title || 'Loading...'}
                    </h1>
                    <Space>
                        <span style={{ color: '#666' }}>Reporter: {defectDetail?.defect_reporter || '-'}</span>
                        <span style={{ color: '#666' }}>|</span>
                        <span style={{ color: '#666' }}>
                            Created: {defectDetail?.defect_created_at ? dayjs(defectDetail.defect_created_at).format('YYYY-MM-DD') : '-'}
                        </span>
                        <span style={{ color: '#666' }}>|</span>
                        <span style={{ color: '#666' }}>
                            Last Updated: {defectDetail?.defect_updated_at ? dayjs(defectDetail.defect_updated_at).format('YYYY-MM-DD') : '-'}
                        </span>
                    </Space>
                </div>

                {/* Role-specific messages and actions */}
                {hasApprovalPermission() && (
                    <div style={{ marginBottom: '20px' }}>
                        {!defectDetail?.is_approved ? (
                            <>
                                <Button
                                    type="primary"
                                    onClick={() => setApprovalModalVisible(true)}
                                    className="custom-ok-button"
                                >
                                    Review Defect
                                </Button>
                                <Text type="secondary" style={{ marginLeft: '10px' }}>
                                    *Tester approval required before making changes
                                </Text>
                            </>
                        ) : (
                            <Text type="success">
                                ✓ Approved by {defectDetail?.approved_by} on {
                                dayjs(defectDetail?.approval_date).format('YYYY-MM-DD HH:mm:ss')
                            }
                            </Text>
                        )}
                    </div>
                )}

                {!hasApprovalPermission() && userRole !== ROLES.OWNER && (
                    <>
                        {defectDetail?.status === DEFECT_STATUS.PENDING && (
                            <div style={{ marginBottom: '20px' }}>
                                <Text type="warning">
                                    Waiting for tester's approval before changes can be made
                                </Text>
                            </div>
                        )}
                    </>
                )}

                {userRole === ROLES.OWNER && (
                    <div style={{ marginBottom: '20px' }}>
                        <Text type="info">
                            Owner access: You can assign tasks and edit approved defects
                        </Text>
                    </div>
                )}

                {/* Main Form */}
                <Form form={form} layout="vertical">
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                        <Card>
                            <div style={{ marginBottom: '16px' }}>
                                <h3 style={{ marginBottom: '8px' }}>Description</h3>
                                <p>{defectDetail?.defect_description || '-'}</p>
                            </div>

                            <div>
                                <h3 style={{ marginBottom: '8px' }}>File Path</h3>
                                {editing ? (
                                    <Form.Item
                                        name="defect_file_path"
                                        style={{ marginBottom: 0 }}
                                        rules={[{ required: true, message: 'File path is required' }]}
                                    >
                                        <Input
                                            placeholder="Enter file path"
                                            style={{ maxWidth: '500px' }}
                                            disabled={!hasEditPermission()}
                                        />
                                    </Form.Item>
                                ) : (
                                    <p>{defectDetail?.defect_file_path || '-'}</p>
                                )}
                            </div>
                        </Card>

                        {/* Details Table */}
                        <Table
                            columns={columns}
                            dataSource={defectDetail ? [defectDetail] : []}
                            loading={loading}
                            pagination={false}
                        />

                        {/* Edit Controls */}
                        {hasEditPermission() && (
                            <div style={{ display: 'flex', gap: '15px', justifyContent: 'flex-end' }}>
                                {editing ? (
                                    <>
                                        <Button
                                            onClick={() => {
                                                setEditing(false);
                                                form.setFieldsValue(defectDetail);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="primary"
                                            onClick={handleSaveChanges}
                                            className="custom-ok-button"
                                        >
                                            Save
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        onClick={() => setEditing(true)}
                                        className="custom-ok-button"
                                    >
                                        Edit Changes
                                    </Button>
                                )}
                            </div>
                        )}

                        {/* Tabs Section */}
                        <Tabs
                            activeKey={activeTab}
                            onChange={(key) => setActiveTab(key)}
                            items={[
                                canAddComments() && {
                                    key: 'defect_comment',
                                    label: 'Comment',
                                    children: <DefectComment
                                        defectId={defectId}
                                        repositoryName={repositoryName}
                                        onActivitiesUpdated={() => {
                                            if (activityStreamRef.current) {
                                                activityStreamRef.current.fetchActivities();
                                            }
                                        }}
                                    />
                                },
                                {
                                    key: 'defect_activity_stream',
                                    label: 'Activity Stream',
                                    children: <DefectActivityStream
                                        ref={activityStreamRef}
                                        defectId={defectId}
                                        repositoryName={repositoryName}
                                        key={defectId}
                                    />
                                },
                                canAddSolutions() && {
                                    key: 'defect_solution',
                                    label: 'Solution',
                                    children: <DefectSolution
                                        defectId={defectId}
                                        repositoryName={repositoryName}
                                        projectName={projectName}  // Thêm prop này vào
                                        onActivitiesUpdated={() => {
                                            if (activityStreamRef.current) {
                                                activityStreamRef.current.fetchActivities();
                                            }
                                        }}
                                    />
                                }
                            ].filter(Boolean)}
                        />
                    </div>
                </Form>

                <ApprovalModal />
            </div>
        </LayoutComponentDetail>
    );
}

export default DefectRepositoryDetail;
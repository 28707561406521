import React, { useState, useEffect } from "react";
import LayoutComponent from "../Layout/LayoutComponent";
import { Table, message, Typography } from "antd";
import axios from '../Ultils/Api';

const { Title } = Typography;

function ListProject() {
    const [projectData, setProjectData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchProjects();
    }, []);

    const fetchProjects = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get('/projects/all', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const { data, status, message: responseMessage } = response.data;

            if (status === 200) {
                const formattedData = data.map(project => ({
                    key: project.id,
                    projectName: project.name,
                    owner: project.ownerUsername,
                    creationTime: new Date(project.createdAt).toLocaleString(),
                    description: project.description
                }));

                setProjectData(formattedData);
            } else {
                message.error(responseMessage || 'Failed to fetch projects');
            }
        } catch (error) {
            console.error('Error fetching projects:', error);
            message.error('Failed to fetch projects: ' + (error.response?.data?.message || error.message));
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            title: 'Project Name',
            dataIndex: 'projectName',
            key: 'projectName',
            render: (text) => (
                <span style={{ color: '#1890ff', fontWeight: 500 }}>{text}</span>
            )
        },
        {
            title: 'Owner',
            dataIndex: 'owner',
            key: 'owner',
            render: (text) => (
                <span style={{ color: '#52c41a' }}>{text}</span>
            )
        },
        {
            title: 'Creation Time',
            dataIndex: 'creationTime',
            key: 'creationTime',
            render: (text) => (
                <span style={{ color: '#722ed1' }}>{text}</span>
            )
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            ellipsis: true,
            render: (text) => (
                <span style={{ color: '#595959' }}>{text || 'No description'}</span>
            )
        }
    ];

    return (
        <LayoutComponent>
            <div style={{
                padding: '24px',
                background: '#fff',
                borderRadius: '8px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.09)'
            }}>
                <div className="content-title" style={{ marginBottom: '24px' }}>
                    <Title level={3} style={{ margin: 0, color: '#1f1f1f' }}>
                        Project List
                    </Title>
                </div>

                <Table
                    columns={columns}
                    dataSource={projectData}
                    loading={loading}
                    pagination={{
                        pageSize: 10,
                        showSizeChanger: true,
                        showTotal: (total) => `Total ${total} projects`,
                        style: { marginTop: '16px' }
                    }}
                    style={{ backgroundColor: '#fff' }}
                    rowClassName={(record, index) =>
                        index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                    }
                />
            </div>

            <style jsx="true">{`
                .table-row-light {
                    background-color: #ffffff;
                }
                .table-row-dark {
                    background-color: #fafafa;
                }
                .table-row-light:hover td,
                .table-row-dark:hover td {
                    background-color: #e6f7ff !important;
                }
                .ant-table-thead > tr > th {
                    background-color: #f0f2f5;
                    color: #262626;
                    font-weight: 600;
                }
            `}</style>
        </LayoutComponent>
    );
}

export default ListProject;
import React from 'react';
import { List, Avatar, Tooltip } from 'antd';
import { UserOutlined, SaveOutlined } from '@ant-design/icons';
import '../Assets/css/ReviewCode.css';

const ActiveUsersList = ({ users, savePermissionHolder }) => {
    const stringToColor = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        const color = Math.abs(hash).toString(16).substring(0, 6);
        return `#${'0'.repeat(6 - color.length)}${color}`;
    };

    const sortedUsers = Array.from(users.entries()).sort((a, b) => {
        if (a[1].username === savePermissionHolder) return -1;
        if (b[1].username === savePermissionHolder) return 1;
        return b[1].joinedAt - a[1].joinedAt;
    });

    return (
        <div className="active-users">
            <div className="active-users-header">
                Active Users
                <span className="active-users-count">{users.size}</span>
            </div>
            <List
                className="active-users-list"
                itemLayout="horizontal"
                dataSource={sortedUsers}
                renderItem={([userId, userData]) => (
                    <List.Item
                        className={`active-users-item ${
                            userData.username === savePermissionHolder ? 'active-users-item-active' : ''
                        }`}
                    >
                        <List.Item.Meta
                            avatar={
                                <div className="active-users-avatar">
                                    <Avatar
                                        icon={<UserOutlined />}
                                        style={{
                                            backgroundColor: stringToColor(userId)
                                        }}
                                    />
                                    <div className="active-users-status" />
                                </div>
                            }
                            title={
                                <div className="active-users-name">
                                    {userData.username}
                                    {savePermissionHolder === userData.username && (
                                        <Tooltip title="Can save changes">
                                            <SaveOutlined className="active-users-save-icon" />
                                        </Tooltip>
                                    )}
                                </div>
                            }
                            description={
                                <span className="active-users-time">
                                    Joined at {new Date(userData.joinedAt).toLocaleTimeString()}
                                </span>
                            }
                        />
                    </List.Item>
                )}
            />
        </div>
    );
};

export default ActiveUsersList;
import React, { useEffect, useState } from "react";
import LayoutComponentDetail from "../Layout/LayoutComponentDetail";
import { Button, Select, Dropdown, Menu, Modal, Form, message, Spin } from "antd";
import {
    BranchesOutlined,
    CopyOutlined,
    DownOutlined,
    HistoryOutlined,
    ScheduleOutlined,
    UserOutlined,
    ArrowLeftOutlined
} from "@ant-design/icons";
import Input from "antd/es/input/Input";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import api from "../Ultils/Api";
import FileTable from "../Shared/FileTable";

const { Option } = Select;

const MenuClone = ({projectName, repositoryName }) => {
    const handleCopy = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            message.success('Copied to clipboard!');
        } catch (err) {
            message.error('Failed to copy');
            console.error('Copy failed:', err);
        }
    };

    const getCloneUrls = () => {
        // Add single quotes to project name if it contains spaces
        const formattedProjectName = projectName.includes(' ') ? `'${projectName}'` : projectName;

        const sshUrl = `git@git.dxgitauto.xyz:${repositoryName}.git`;
        const httpsUrl = `https://git.dxgitauto.xyz/git/${formattedProjectName}/${repositoryName}.git`;
        return { sshUrl, httpsUrl };
    };

    const { sshUrl, httpsUrl } = getCloneUrls();

    return (
        <Menu onClick={e => e.domEvent.stopPropagation()}>
            <Menu.Item key="ssh" onClick={e => e.domEvent.stopPropagation()}>
                <div>
                    Clone with SSH
                    <div className="repository-action-clone-item">
                        <Input value={sshUrl} readOnly />
                        <Button
                            icon={<CopyOutlined />}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleCopy(sshUrl);
                            }}
                        />
                    </div>
                </div>
            </Menu.Item>
            <Menu.Item key="https" onClick={e => e.domEvent.stopPropagation()}>
                <div>
                    Clone with HTTPS
                    <div className="repository-action-clone-item">
                        <Input value={httpsUrl} readOnly />
                        <Button
                            icon={<CopyOutlined />}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleCopy(httpsUrl);
                            }}
                        />
                    </div>
                </div>
            </Menu.Item>
        </Menu>
    );
};

function RepositoryDetail() {
    // States
    const [isModalVisibleCreate, setIsModalVisibleCreate] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [newBranchName, setNewBranchName] = useState('');
    const [createFrom, setCreateFrom] = useState('main');
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState('');
    const [repoInfo, setRepoInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [branchesLoading, setBranchesLoading] = useState(false);
    const [list, setList] = useState([]);
    const [selectedFile, setSelectedFile] = useState('');

    // Hooks
    const navigate = useNavigate();
    const location = useLocation();
    const { projectName, repositoryName } = useParams();

    // API Functions
    const fetchBranches = async () => {
        setBranchesLoading(true);
        try {
            const response = await api.get(`/git/project/${projectName}/repositories/${repositoryName}/branches`);

            // Kiểm tra và lấy danh sách branches từ response
            if (response.data?.branches) {
                // Map để lấy chỉ tên của các branches
                const branchList = response.data.branches.map(branch => branch.name);
                setBranches(branchList);

                // Nếu chưa có branch được chọn, tự động chọn branch mặc định
                if (!selectedBranch) {
                    // Ưu tiên chọn 'main' hoặc 'master' làm branch mặc định
                    const defaultBranch = branchList.find(b => b === 'main' || b === 'master') || branchList[0];
                    setSelectedBranch(defaultBranch);
                    setCreateFrom(defaultBranch);
                }
            }
        } catch (error) {
            // Xử lý lỗi
            console.error('Error fetching branches:', error);
            if (error.response?.status === 401) {
                message.error('Session expired. Please login again.');
                navigate('/login');
            } else {
                message.error('Failed to fetch branches');
            }
        } finally {
            setBranchesLoading(false);
        }
    };
    const fetchRepositoryData = async () => {
        setLoading(true);
        try {
            const repoResponse = await api.get(`/git/project/${projectName}/repositories/${repositoryName}/info`);
            console.log('Repository Info Response:', repoResponse.data);  // Log repo info
            const repoInformation = repoResponse.data;
            setRepoInfo(repoInformation);

            const listResponse = await api.get(
                `/git/project/${projectName}/repositories/${repositoryName}/branches/${selectedBranch}/files`
            );
            console.log('Files List Response:', listResponse.data);  // Log files list
            const listData = listResponse.data;

            if (listData && listData.files) {
                const listFile = listData.files.map((file, index) => ({
                    key: index,
                    name: file.name,
                    lastCommit: file.lastCommit,
                    lastUpdate: file.lastUpdate,
                    type: file.type || file.type,
                    path: file.path || file.path
                }));
                console.log('Processed Files List:', listFile);  // Log processed files
                setList(listFile);
            } else {
                setList([]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);  // Log any errors
        } finally {
            setLoading(false);
        }
    };

    // Fetch repository files
    const fetchFile = async () => {
        setLoading(true);
        try {
            const repoResponse = await api.get(`/git/project/${projectName}/repositories/${repositoryName}/info`);
            setRepoInfo(repoResponse.data);

            const pathParts = location.pathname.split('/');
            const branchIndex = pathParts.indexOf(selectedBranch);
            const currentPath = branchIndex !== -1 ? pathParts.slice(branchIndex + 1).join('/') : '';

            const listFileResponse = await api.get(
                `/git/${projectName}/repositories/${repositoryName}/branches/${selectedBranch}/files/${currentPath}`
            );
            const listFile1 = listFileResponse.data;

            if (listFile1) {
                const isFileView = location.pathname.includes('/blob/');

                if (isFileView && listFile1.length === 1) {
                    const file = listFile1[0];
                    try {
                        const contentResponse = await api.get(
                            `/git/${projectName}/repositories/${repositoryName}/branches/${selectedBranch}/files/${file.path}`
                        );

                        const fileData = {
                            key: 0,
                            name: file.path.split('/').pop(),
                            lastCommit: file.lastCommit,
                            lastUpdate: file.lastUpdate,
                            type: file.type || 'unknown',
                            path: file.path || '',
                            content: contentResponse.data.content
                        };

                        setList([fileData]);
                        navigate(`/${repositoryName}/review-code`, {
                            state: {
                                fileData: fileData,
                                repositoryName,
                                selectedBranch
                            }
                        });
                    } catch (contentError) {
                        console.error('Error fetching file content:', contentError);
                        message.error('Failed to load file content');
                    }
                } else {
                    if (Array.isArray(listFile1)) {
                        const listFileRes = listFile1.map((file, index) => ({
                            key: index,
                            name: file.path.split('/').pop(),
                            lastCommit: file.lastCommit,
                            lastUpdate: file.lastUpdate,
                            type: file.type || 'unknown',
                            path: file.path || '',
                        }));
                        setList(listFileRes);
                    } else {
                        console.error('listFile1 is not an array:', listFile1);
                        setList([]);
                    }
                }
            } else {
                setList([]);
            }
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    // Handle file/directory click
    const handleNameClick = async (record) => {
        const pathParts = location.pathname.split('/');
        const isInFileView = pathParts.includes('tree') || pathParts.includes('blob');
        const branchIndex = pathParts.indexOf(selectedBranch);
        const currentPath = isInFileView && branchIndex !== -1
            ? pathParts.slice(branchIndex + 1).join('/')
            : '';

        const newPath = currentPath
            ? `${currentPath}/${record.name}`
            : record.name;

        setSelectedFile(newPath);

        if (record.type === 'directory' || record.type === 'dir') {
            navigate(`/project/${projectName}/repository/${repositoryName}/tree/${selectedBranch}/${newPath}`);
        } else {
            try {
                setLoading(true);
                const contentResponse = await api.get(
                    `/git/${projectName}/repositories/${repositoryName}/branches/${selectedBranch}/files/${record.path}`
                );

                const fileData = {
                    ...record,
                    content: contentResponse.data.content
                };

                navigate(`/project/${projectName}/repository/${repositoryName}/blob/${selectedBranch}/${record.path}`, {
                    state: {
                        fileData,
                        repositoryName,
                        selectedBranch,
                        projectName
                    }
                });
            } catch (error) {
            } finally {
                setLoading(false);
            }
        }
    };

    const handleCreateBranch = async (e) => {
        e.preventDefault();
        if (!newBranchName || !createFrom) {
            message.error('Please fill in all required fields');
            return;
        }

        try {
            await api.post(`/git/project/${projectName}/repositories/${repositoryName}/branches`, {
                sourceBranch: createFrom,
                newBranch: newBranchName
            });

            // Bỏ kiểm tra response.data?.status === 200
            message.success('Branch created successfully');
            setIsModalVisibleCreate(false); // Đóng modal
            setNewBranchName('');
            setCreateFrom(selectedBranch);

            // Refresh data
            await Promise.all([
                fetchBranches(),
                fetchRepositoryData()
            ]);

        } catch (error) {
            handleError(error);
        }
    };

    const handleDeleteRepository = async () => {
        try {
            const response = await api.delete(`/projects/${projectName}/repositories/${repositoryName}`);

            if (response.data?.status === 200) {
                message.success('Repository deleted successfully');
                navigate(`/project/${projectName}`);
            }
        } catch (error) {
            handleError(error);
        }
    };

    // UI Handlers

    const handleBack = () => {
        const pathParts = location.pathname.split('/');
        if (pathParts.includes('tree') || pathParts.includes('blob')) {
            const branchIndex = pathParts.indexOf(selectedBranch);
            const currentPathParts = pathParts.slice(branchIndex + 1);

            if (currentPathParts.length > 0) {
                const parentPathParts = currentPathParts.slice(0, -1);
                if (parentPathParts.length > 0) {
                    navigate(`/project/${projectName}/repository/${repositoryName}/tree/${selectedBranch}/${parentPathParts.join('/')}`);
                    setSelectedFile(parentPathParts.join('/'));
                } else {
                    navigate(`/project/${projectName}/repository/${repositoryName}/tree/${selectedBranch}`);
                    setSelectedFile('');
                }
            } else {
                navigate(`/project/${projectName}/repository/${repositoryName}`);
                setSelectedFile('');
            }
        } else {
            navigate(`/project/${projectName}`);
        }
    };

    const handleError = (error) => {
        console.error('API Error:', error);
        if (error.response?.status === 401) {
            message.error('Session expired. Please login again.');
            navigate('/login');
        } else {
            message.error(error.response?.data?.message || 'Operation failed');
        }
    };

    // Effects
    useEffect(() => {
        if (repositoryName && projectName) {
            fetchBranches();
        }
    }, [repositoryName, projectName]);

    useEffect(() => {
        if (repositoryName && projectName && selectedBranch) {
            if (selectedFile) {
                fetchFile();
            } else {
                fetchRepositoryData();
            }
        }
    }, [repositoryName, projectName, selectedBranch, selectedFile, location.pathname]);

    return (
        <LayoutComponentDetail>
            <Spin spinning={branchesLoading} tip="Loading repository...">
                <div className="content-title">
                    <Button
                        icon={<ArrowLeftOutlined/>}
                        onClick={handleBack}
                        style={{marginRight: '16px'}}
                    >
                        Back
                    </Button>
                    <h1>{repositoryName}</h1>
                </div>

                <div style={{marginLeft:'20px',marginBottom:'20px'}}>
                    <Select
                        value={selectedBranch}
                        style={{width: '110px'}}
                        onChange={(value) => setSelectedBranch(value)}
                        loading={branchesLoading}
                    >
                        {branches.map((branch, index) => (
                            <Option key={`branch${index + 1}`} value={branch}>{branch}</Option>
                        ))}
                    </Select>
                </div>

                <div style={{
                    display:'flex',
                    justifyContent: 'flex-end',
                    marginRight: '20px',
                    marginBottom: '10px',
                    gap: '10px'
                }}>
                    <Dropdown
                        overlay={<MenuClone projectName={projectName} repositoryName={repositoryName}/>}
                        trigger={['click']}
                        className="repository-action-clone"
                        destroyPopupOnHide
                    >
                        <Button className="custom-ok-button">
                            Clone <DownOutlined/>
                        </Button>
                    </Dropdown>
                    <Button onClick={() => setIsModalVisibleCreate(true)}>Create Branch</Button>
                    {/*<Button*/}
                    {/*    onClick={() => setIsDeleteModalVisible(true)}*/}
                    {/*>*/}
                    {/*    Delete Repository*/}
                    {/*</Button>*/}
                </div>

                <div className="repository-detail-container">
                    <FileTable
                        list={list}
                        loading={loading}
                        onNameClick={handleNameClick}
                    />
                    <div className="repository-information">
                        <Spin spinning={loading}>
                            <h1>Information</h1>
                            <div className="repository-information-items">
                                <div className="repository-information-item">
                                    <p className="repository-information-title">
                                        <ScheduleOutlined/> Created
                                    </p>
                                    <p>{repoInfo?.info?.created}</p>
                                </div>
                                <p>-------------------------------------</p>
                                <div className="repository-information-item">
                                    <p className="repository-information-title">
                                        <UserOutlined/> Creator
                                    </p>
                                    <p>{repoInfo?.info?.creator}</p>
                                </div>
                                <div className="repository-information-item">
                                    <p>
                                        <HistoryOutlined/> {repoInfo?.info?.numCommits} commits
                                    </p>
                                </div>
                                <div className="repository-information-item">
                                    <p>
                                        <BranchesOutlined/> {repoInfo?.info?.numBranches} branches
                                    </p>
                                </div>
                            </div>
                        </Spin>
                    </div>
                </div>
            </Spin>

            {/* Create Branch Modal */}
            <Modal
                title="Create Branch"
                open={isModalVisibleCreate} // Thay visible thành open
                onOk={handleCreateBranch}
                onCancel={() => setIsModalVisibleCreate(false)}
                okText="Create"
                cancelText="Cancel"
                okButtonProps={{className: 'custom-ok-button'}}
            >
                <Form layout="vertical">
                    <Form.Item
                        label="Branch Name:"
                        name="branchName"
                        rules={[
                            {required: true, message: 'Please input branch name'},
                            {max: 30, message: 'Branch name cannot be more than 30 characters long'}
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            type="text"
                            placeholder="Branch Name"
                            value={newBranchName}
                            onChange={(e) => setNewBranchName(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Create From:"
                        name="createFrom"
                        validateTrigger="onBlur"
                    >
                        <Select
                            defaultValue={selectedBranch}
                            onChange={(value) => setCreateFrom(value)}
                        >
                            {branches.map((branch, index) => (
                                <Option key={`branch${index + 1}`} value={branch}>{branch}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>

            {/* Delete Repository Modal */}
            <Modal
                title="Delete Repository"
                open={isDeleteModalVisible}
                onOk={handleDeleteRepository}
                onCancel={() => setIsDeleteModalVisible(false)}
                okText="Delete"
                cancelText="Cancel"
                okButtonProps={{ danger: true }}
            >
                <p>Are you sure you want to delete repository <strong>{repositoryName}</strong>?</p>
                <p>This action cannot be undone.</p>
            </Modal>
        </LayoutComponentDetail>
    );
}

export default RepositoryDetail;
import React, { useState, useEffect } from "react";
import { Card, message } from 'antd';
import { UserOutlined, ProjectOutlined } from '@ant-design/icons';
import LayoutComponent from "../Layout/LayoutComponent";
import '../Assets/css/dashboard.css'
import UserChart from "./UserChart";
import { useNavigate } from "react-router-dom";
import ProjectChart from "./ProjectChart";
import api from '../Ultils/Api';

function Dashboard() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({
        totalProjects: 0,
        totalUsers: 0
    });

    useEffect(() => {
        fetchDashboardData();
    }, []);

    const fetchDashboardData = async () => {
        try {
            setLoading(true);
            const [usersResponse, projectsResponse] = await Promise.all([
                api.get('/statistics/users/monthly'),
                api.get('/statistics/projects/monthly')
            ]);

            if (usersResponse.data?.status === 200 && projectsResponse.data?.status === 200) {
                // Get the latest data from each response
                const latestUserData = usersResponse.data.data[usersResponse.data.data.length - 1] || { totalUsers: 0 };
                const latestProjectData = projectsResponse.data.data[projectsResponse.data.data.length - 1] || { totalProjects: 0 };

                setData({
                    totalUsers: latestUserData.totalUsers,
                    totalProjects: latestProjectData.totalProjects
                });
            }
        } catch (error) {
            if (error.response?.status === 401) {
                message.error("Please log in again");
                navigate('/login');
            } else {
                message.error("Failed to fetch dashboard data");
            }
        } finally {
            setLoading(false);
        }
    };

    const handleClickCardUser = () => {
        navigate('/user')
    }

    const handleClickCardProject = () => {
        navigate('/list_project')
    }

    return (
        <LayoutComponent>
            <div className="dashboard-container-admin">
                <h1 className="content-title-title">Dashboard</h1>

                <div className={'user-container'}>
                    <Card
                        className="stats-card"
                        onClick={handleClickCardUser}
                        style={{cursor:'pointer'}}
                        loading={loading}
                    >
                        <div className="stats-content">
                            <UserOutlined className="stats-icon" />
                            <div className="stats-info">
                                <div className="stats-label">
                                    Total Users
                                </div>
                                <div className="stats-value">
                                    {data.totalUsers.toLocaleString()}
                                </div>
                            </div>
                        </div>
                    </Card>
                    <div style={{marginBottom:'20px'}}>
                        <UserChart/>
                    </div>
                </div>

                <div className={'project-container'}>
                    <Card
                        className="stats-card"
                        onClick={handleClickCardProject}
                        style={{cursor:'pointer'}}
                        loading={loading}
                    >
                        <div className="stats-content">
                            <ProjectOutlined className="stats-icon" />
                            <div className="stats-info">
                                <div className="stats-label">
                                    Total Projects
                                </div>
                                <div className="stats-value">
                                    {data.totalProjects.toLocaleString()}
                                </div>
                            </div>
                        </div>
                    </Card>
                    <div style={{marginTop:'20px'}}>
                        <ProjectChart/>
                    </div>
                </div>
            </div>
        </LayoutComponent>
    );
}

export default Dashboard;
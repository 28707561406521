import React, {useState, useEffect} from "react";
import LayoutComponentDetail from "../Layout/LayoutComponentDetail";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Card, Select, Statistic} from "antd";
import {BranchesOutlined} from "@ant-design/icons";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip} from "recharts";
import api from "../Ultils/Api";

function ContributeChartProject() {
    const { repositoryName } = useParams();
    const { Option } = Select;
    const navigate = useNavigate();
    const [selectedBranch, setSelectedBranch] = useState('all');
    const [commitData, setCommitData] = useState([]);
    const [totalCommits, setTotalCommits] = useState(0);
    const [commitDataLoading, setCommitDataLoading] = useState(true);

    const branchList = [
        {id:'1',name:'PhongVD'},
        {id:'2',name:'DucVD'},
    ];

    // Transform month number to month name
    const getMonthName = (monthNum) => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return months[monthNum - 1] || '';
    };

    useEffect(() => {
        const fetchCommitData = async () => {
            setCommitDataLoading(true);
            try {
                const response = await api.get(`/git/count-commit/${repositoryName}`);
                const data = response.data;

                if (!data) {
                    throw new Error('No data received');
                }

                // Transform API data to match chart format
                const transformedData = data.monthly_stats
                    ?.filter(stat => stat.month > 0 && stat.month <= 12)
                    ?.map(stat => ({
                        month: getMonthName(stat.month),
                        commits: stat.commits
                    })) || [];

                setCommitData(transformedData);
                setTotalCommits(data.total_commits || 0);
            } catch (error) {
                // Kiểm tra loại lỗi cụ thể
                if (error.response) {
                    // Server trả về response với status code nằm ngoài range 2xx
                    console.error('Error response:', error.response.data);
                    console.error('Error status:', error.response.status);
                } else if (error.request) {
                    // Request được gửi nhưng không nhận được response
                    console.error('Error request:', error.request);
                } else {
                    // Có lỗi khi setting up request
                    console.error('Error message:', error.message);
                }

                // Set empty data khi có lỗi
                setCommitData([]);
                setTotalCommits(0);
            } finally {
                setCommitDataLoading(false);
            }
        };

        if (repositoryName) {
            fetchCommitData();
        }
    }, [repositoryName]);

    const ToolHover = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{
                    backgroundColor: 'white',
                    padding: '8px 12px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }}>
                    <p style={{ margin: 0 }}>{`Month: ${label}`}</p>
                    <p style={{ margin: '4px 0 0 0', fontWeight: 500 }}>
                        {`Commits: ${payload[0].value}`}
                    </p>
                </div>
            );
        }
        return null;
    };

    const handleSelectChange = (value) => {
        if (!repositoryName) {
            console.error('Repository name is undefined');
            return;
        }

        const basePath = `/repository/${repositoryName}`;
        switch (value) {
            case 'contributeChart':
                navigate(`${basePath}/contribute`);
                break;
            case 'listMember':
                navigate(`${basePath}/member`);
                break;
            default:
                break;
        }
    };

    const handleHistory = () => {
        navigate(`/repository/${repositoryName}/commit`)
    };

    return (
        <LayoutComponentDetail>
            <div className={'content-title'}>
                <h1>Contribute Chart</h1>
            </div>
            <div className={'contribute-chart-project-container'}>
                <div className={'contribute-chart-action'}>
                    <Select defaultValue={'contributeChart'} onChange={handleSelectChange} style={{width: '160px'}}>
                        <Option value={'listMember'}>List Member</Option>
                        <Option value={'contributeChart'}>Contribute Chart</Option>
                    </Select>
                    <Button onClick={handleHistory} className={'btn-history'}>History</Button>
                </div>
                <div className={'contribute-chart-card-information'}>
                    <Card>
                        <Statistic
                            title={'Total Commits'}
                            value={totalCommits}
                            prefix={<BranchesOutlined/>}
                        />
                    </Card>
                </div>
                <div className={'contribute-chart-project-graph'}>
                    <Card style={{ marginTop: '24px' }}>
                        <h3>Commits to {repositoryName}</h3>
                        <div style={{ height: '400px', width: '100%', marginTop: '20px' }}>
                            <ResponsiveContainer>
                                <BarChart
                                    data={commitData}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                                    <XAxis
                                        dataKey="month"
                                        axisLine={false}
                                    />
                                    <YAxis
                                        domain={[0, 'auto']}
                                        axisLine={false}
                                    />
                                    <Tooltip content={<ToolHover />} />
                                    <Bar
                                        dataKey="commits"
                                        fill="#f84502"
                                        radius={[2, 2, 0, 0]}
                                        maxBarSize={50}
                                    />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </Card>
                </div>
            </div>
        </LayoutComponentDetail>
    );
}

export default ContributeChartProject;
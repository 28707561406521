import React, { useState, useEffect } from "react";
import {
    Button,
    List,
    Typography,
    Modal,
    Form,
    Input,
    message,
    Row,
    Col,
    Select,
    Divider
} from "antd";
import {
    UserOutlined,
    ClockCircleOutlined,
    ArrowLeftOutlined,
    SortAscendingOutlined,
    SortDescendingOutlined,
} from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";
import LayoutComponentDetail from "../Layout/LayoutComponentDetail";
import Search from "antd/es/input/Search";
import api from "../Ultils/Api";

const { Option } = Select;
const { Text } = Typography;

function ProjectDetails() {
    const { projectName } = useParams();
    const decodedProjectName = decodeURIComponent(projectName);
    const navigate = useNavigate();
    const [form] = Form.useForm();

    // State for data
    const [repositories, setRepositories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortField, setSortField] = useState('name');

    // Modal states
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isRepoModalVisible, setIsRepoModalVisible] = useState(false);

    // API Functions
    const fetchRepositories = async () => {
        setLoading(true);
        try {
            const response = await api.get(`/projects/${decodedProjectName}/repositories`);
            if (response.data?.status === 200) {
                setRepositories(response.data.data || []);
            } else {
                throw new Error(response.data?.message || "Failed to fetch repositories");
            }
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRepositories();
    }, [decodedProjectName]);

    const handleCreateRepository = async () => {
        try {
            await form.validateFields();
            const values = form.getFieldsValue();

            const response = await api.post(`/projects/${decodedProjectName}/repositories`, {
                name: values.repositoryName.trim()
            });

            if (response.data?.status === 200) {
                message.success('Repository created successfully');
                setIsRepoModalVisible(false);
                form.resetFields();
                fetchRepositories();
            }
        } catch (error) {
            if (!error.errorFields) {
                handleError(error);
            }
        }
    };

    const deleteProject = async () => {
        try {
            const response = await api.delete(`/projects/${decodedProjectName}`);
            if (response.data?.status === 200) {
                message.success('Project deleted successfully');
                navigate('/');
            }
        } catch (error) {
            handleError(error);
        }
    };

    // Error handling
    const handleError = (error) => {
        console.error("Error:", error);
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    message.error("Your session has expired. Please log in again.");
                    navigate('/login');
                    break;
                case 403:
                    message.error("You don't have permission to perform this action.");
                    break;
                case 400:
                    message.error(error.response.data?.message || "Invalid request");
                    break;
                default:
                    message.error(error.response.data?.message || "An error occurred.");
            }
        } else {
            message.error("Network error. Please check your connection.");
        }
    };

    // UI handlers
    const handleBack = () => {
        navigate('/');
    };

    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    const handleSortChange = (value) => {
        setSortField(value);
    };

    const toggleSortOrder = () => {
        setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
    };

    const handleRepositoryClick = (repoName) => {
        navigate(`/project/${decodedProjectName}/repository/${repoName}`);
    };

    // Filter and sort repositories
    const sortedAndFilteredRepositories = repositories
        .filter(repo => {
            const searchLower = searchTerm.toLowerCase();
            return repo.name.toLowerCase().includes(searchLower) ||
                (repo.ownerName && repo.ownerName.toLowerCase().includes(searchLower)) ||
                (repo.ownerEmail && repo.ownerEmail.toLowerCase().includes(searchLower));
        })
        .sort((a, b) => {
            let fieldA, fieldB;

            switch(sortField) {
                case 'ownerName':
                    fieldA = a.ownerName || '';
                    fieldB = b.ownerName || '';
                    break;
                case 'createdAt':
                    fieldA = new Date(a.createdAt).getTime();
                    fieldB = new Date(b.createdAt).getTime();
                    break;
                default:
                    fieldA = a[sortField];
                    fieldB = b[sortField];
            }

            if (fieldA < fieldB) return sortOrder === 'asc' ? -1 : 1;
            if (fieldA > fieldB) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });

    return (
        <LayoutComponentDetail>
            <div className="project-detail-container">
                <div className="page-header">
                    <Button
                        icon={<ArrowLeftOutlined/>}
                        onClick={handleBack}
                        style={{marginLeft: '20px'}}
                    >
                        Back To Projects
                    </Button>
                    <div className={'content-title'}>
                        <h1>Repository of '{decodedProjectName}'</h1>
                    </div>
                </div>

                <Row gutter={16} className="search-sort-container">
                    <Col span={18}>
                        <Search
                            placeholder="Search Repositories"
                            allowClear
                            onSearch={handleSearch}
                            style={{width: '98%', marginLeft: '20px'}}
                        />
                    </Col>
                    <Col span={4}>
                        <Select
                            defaultValue="name"
                            style={{width: '100%'}}
                            onChange={handleSortChange}
                        >
                            <Option value="name">Name</Option>
                            <Option value="ownerName">Owner</Option>
                            <Option value="createdAt">Created At</Option>
                        </Select>
                    </Col>
                    <Col span={2}>
                        <Button
                            type="text"
                            icon={sortOrder === 'asc' ? <SortAscendingOutlined/> : <SortDescendingOutlined/>}
                            className="sort-btn"
                            onClick={toggleSortOrder}
                            style={{width: '83%'}}
                        />
                    </Col>
                </Row>

                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginRight: '20px',
                    marginTop: '20px',
                    marginBottom: '10px',
                    gap: '10px'
                }}>
                    <Button
                        className={'custom-ok-button'}
                        onClick={() => setIsRepoModalVisible(true)}
                    >
                        Create Repository
                    </Button>
                    {/*<Button*/}
                    {/*    onClick={() => setIsDeleteModalVisible(true)}*/}
                    {/*>*/}
                    {/*    Delete Project*/}
                    {/*</Button>*/}
                </div>

                <div className="list-repo">
                    <List
                        dataSource={sortedAndFilteredRepositories}
                        loading={loading}
                        renderItem={(repo) => (
                            <List.Item onClick={() => handleRepositoryClick(repo.name)}>
                                <div className="repository-item">
                                    <div className="repo-left">
                                        <div className="repo-avatar">
                                            {repo.name.charAt(0).toUpperCase()}
                                        </div>
                                        <div className="repo-main">
                                            <Text strong className="repo-name">{repo.name}</Text>
                                            <div className="repo-stats">
                                                <div className="stat-item">
                                                    <UserOutlined/>
                                                    <Text>Owner: {repo.ownerName || 'Unknown'}</Text>
                                                </div>
                                                <Divider type="vertical"/>
                                                <div className="stat-item">
                                                    <ClockCircleOutlined/>
                                                    <Text>Created: {new Date(repo.createdAt).toLocaleDateString()}</Text>
                                                </div>
                                                {repo.ownerEmail && (
                                                    <>
                                                        <Divider type="vertical"/>
                                                        <div className="stat-item">
                                                            <Text type="secondary">{repo.ownerEmail}</Text>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </List.Item>
                        )}
                    />
                </div>

                {/* Create Repository Modal */}
                <Modal
                    title="Create Repository"
                    open={isRepoModalVisible}
                    onOk={handleCreateRepository}
                    onCancel={() => {
                        setIsRepoModalVisible(false);
                        form.resetFields();
                    }}
                    okText="Create"
                    cancelText="Cancel"
                    okButtonProps={{className: 'custom-ok-button'}}
                >
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <Form.Item
                            label="Repository Name"
                            name="repositoryName"
                            rules={[
                                {required: true, message: 'Please input repository name'},
                                {min: 3, message: 'Repository name must be at least 3 characters'},
                                {
                                    pattern: /^[a-zA-Z0-9-_]+$/,
                                    message: 'Repository name can only contain letters, numbers, hyphens and underscores'
                                }
                            ]}
                        >
                            <Input placeholder="Enter repository name"/>
                        </Form.Item>
                    </Form>
                </Modal>

                {/* Delete Project Modal */}
                <Modal
                    title="Delete Project"
                    open={isDeleteModalVisible}
                    onOk={deleteProject}
                    onCancel={() => setIsDeleteModalVisible(false)}
                    okText="Delete"
                    cancelText="Cancel"
                    okButtonProps={{ danger: true }}
                >
                    <p>Are you sure you want to delete project <strong>{decodedProjectName}</strong>?</p>
                    <p>This action cannot be undone and will delete all repositories in this project.</p>
                </Modal>
            </div>
        </LayoutComponentDetail>
    );
}

export default ProjectDetails;
import React from "react";
import { Layout} from "antd";
import SiderBarDetail from "./SiderBarDetail";
import HeaderComponent from "./HeaderComponent";
function LayoutComponentDetail({children}) {
    return(
        <>
            <Layout style={{ minHeight: '100vh' }}>
                <SiderBarDetail />
                <Layout style={{ marginLeft: 250 }}>
                    <HeaderComponent />
                    <div className="main-content">
                        {children}
                    </div>
                </Layout>
            </Layout>
        </>
    )
}
export default LayoutComponentDetail
import React from "react";
import { Layout} from "antd";
import SideBar from "./SideBar";
import HeaderComponent from "./HeaderComponent";
import '../Assets/css/index.css'


function LayoutComponent({children}) {
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <SideBar />
            <Layout style={{ marginLeft: 250 }}>
                <HeaderComponent />
                <div className="main-content">
                    {children}
                </div>
            </Layout>
        </Layout>
    );
}
export default LayoutComponent
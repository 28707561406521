import React, { useState } from "react";
import { Button, Card, Input, Radio, Typography, Form, message } from "antd";

function EditProfile({ userInfo, onSave, onCancel }) {
    const { Text } = Typography;
    const [form] = Form.useForm();
    const [editedInfo, setEditedInfo] = useState(userInfo);

    const validatePhoneNumber = (_, value) => {
        const vietnamesePhoneRegex = /^(0[3|5|7|8|9])+([0-9]{8})\b/;
        if (!value) {
            return Promise.resolve();
        }
        if (!vietnamesePhoneRegex.test(value)) {
            return Promise.reject('Vui lòng nhập số điện thoại hợp lệ (VD: 0912345678)');
        }
        return Promise.resolve();
    };

    const handleInputChange = (field, value) => {
        setEditedInfo(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleSave = async () => {
        try {
            await form.validateFields();
            onSave(editedInfo);
        } catch (error) {
            message.error('Please fix the validation errors before saving');
        }
    };

    return (
        <Card title={'Edit Information'} className={'profile-information-card ant-card'}>
            <Form
                form={form}
                initialValues={userInfo}
                layout="vertical"
                className={'profile-information-content'}
            >
                <div className={'profile-information-grid'}>
                    <div className={'profile-information-item'}>
                        <Form.Item
                            label="Name"
                            name="name"
                        >
                            <Input
                                className={'profile-information-input'}
                                value={editedInfo.name}
                                onChange={(e) => handleInputChange('name', e.target.value)}
                            />
                        </Form.Item>
                    </div>
                    <div className={'profile-information-item'}>
                        <Form.Item
                            label="Gender"
                            name="gender"
                        >
                            <Radio.Group
                                value={editedInfo.gender}
                                onChange={(e) => handleInputChange('gender', e.target.value)}
                                className={'profile-information-input'}
                            >
                                <Radio value={'Male'}>Male</Radio>
                                <Radio value={'Female'}>Female</Radio>
                                <Radio value={'Other'}>Other</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </div>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        { required: true, message: 'Please input your email' },
                        { type: 'email', message: 'Please enter a valid email address' },
                        {
                            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: 'Please enter a valid email format (e.g., example@domain.com)'
                        }
                    ]}
                >
                    <Input
                        className={'profile-information-input'}
                        value={editedInfo.email}
                        onChange={(e) => handleInputChange('email', e.target.value)}
                    />
                </Form.Item>

                <Form.Item
                    label="Date of birth"
                    name="dateOfBirth"
                >
                    <Input
                        type={'date'}
                        className={'profile-information-input'}
                        value={editedInfo.dateOfBirth}
                        onChange={(e) => handleInputChange('dateOfBirth', e.target.value)}
                    />
                </Form.Item>

                <Form.Item
                    label="Country"
                    name="country"
                >
                    <Input
                        className={'profile-information-input'}
                        value={editedInfo.country}
                        onChange={(e) => handleInputChange('country', e.target.value)}
                    />
                </Form.Item>

                <Form.Item
                    label="Phone number"
                    name="phoneNumber"
                    rules={[
                        { validator: validatePhoneNumber },
                        {
                            pattern: /^(0[3|5|7|8|9])+([0-9]{8})\b/,
                            message: 'Số điện thoại phải bắt đầu bằng 03, 05, 07, 08, 09 và có 10 chữ số'
                        }
                    ]}
                >
                    <Input
                        className={'profile-information-input'}
                        value={editedInfo.phoneNumber}
                        onChange={(e) => handleInputChange('phoneNumber', e.target.value)}
                    />
                </Form.Item>

                <div className={'profile-button-container'}>
                    <Button
                        className={'profile-button'}
                        onClick={handleSave}
                        type={'primary'}
                    >
                        Save
                    </Button>
                    <Button
                        className={'profile-button-cancel'}
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                </div>
            </Form>
        </Card>
    );
}

export default EditProfile;
import React, { useState } from "react";
import { Button, Card, Input, Typography, message, Alert } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import api from "../Ultils/Api";

function ChangePassword() {
    const { Text } = Typography;
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);

    // States for password visibility
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const passwordRequirements = (
        <Alert
            type="info"
            showIcon
            className="mb-4"
            message="Password Requirements"
            description={
                <ul className="list-disc pl-4">
                    <li>At least 8 characters long</li>
                    <li>Must contain at least one uppercase letter</li>
                    <li>Must contain at least one special character ( ! @ # $ % ^ & * ( ) - _ = + {'['} {']'} {'{'} {'}'} ; : &quot; &apos; , . &lt; &gt; / ? )</li>
                </ul>
            }
        />
    );

    const handleChangePassword = async () => {
        if (!currentPassword || !newPassword || !confirmPassword) {
            message.error("Please fill in all fields");
            return;
        }

        if (newPassword !== confirmPassword) {
            message.error("New passwords do not match");
            return;
        }

        setLoading(true);
        try {
            const response = await api.post("/git/change-password", {
                currentPassword: currentPassword,
                newPassword: newPassword,
                rePassword: confirmPassword
            });

            const { status, message: responseMessage, data } = response.data;

            if (status === 200 && data?.success) {
                message.success(data.message || "Password changed successfully");
                setCurrentPassword("");
                setNewPassword("");
                setConfirmPassword("");
            } else {
                message.error(responseMessage || data?.message || "Failed to change password");
            }
        } catch (error) {
            const errorData = error.response?.data;
            const errorMessage = errorData?.message ||
                errorData?.data?.message ||
                "Failed to change password";
            message.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Card
            title={'Change Password'}
            className={'profile-change-password-card ant-card'}
        >
            {passwordRequirements}
            <div className={'profile-change-password-content'}>
                <div className={'profile-change-password-item'}>
                    <Text className={'profile-change-password-label'}>Current Password:</Text>
                    <Input
                        type={showCurrentPassword ? "text" : "password"}
                        className={'profile-change-password-input'}
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        suffix={
                            <span onClick={() => setShowCurrentPassword(!showCurrentPassword)} style={{ cursor: 'pointer' }}>
                                {showCurrentPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                            </span>
                        }
                    />
                </div>
                <div className={'profile-change-password-item'}>
                    <Text className={'profile-change-password-label'}>New Password:</Text>
                    <Input
                        type={showNewPassword ? "text" : "password"}
                        className={'profile-change-password-input'}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        suffix={
                            <span onClick={() => setShowNewPassword(!showNewPassword)} style={{ cursor: 'pointer' }}>
                                {showNewPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                            </span>
                        }
                    />
                </div>
                <div className={'profile-change-password-item'}>
                    <Text className={'profile-change-password-label'}>Confirm New Password:</Text>
                    <Input
                        type={showConfirmPassword ? "text" : "password"}
                        className={'profile-change-password-input'}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        suffix={
                            <span onClick={() => setShowConfirmPassword(!showConfirmPassword)} style={{ cursor: 'pointer' }}>
                                {showConfirmPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                            </span>
                        }
                    />
                </div>
                <div className={'profile-button-container'}>
                    <Button
                        className={'profile-button'}
                        onClick={handleChangePassword}
                        loading={loading}
                        type="primary"
                    >
                        Change Password
                    </Button>
                </div>
            </div>
        </Card>
    );
}

export default ChangePassword;
import React, { useState, useEffect } from "react";
import LayoutComponentDetail from "../Layout/LayoutComponentDetail";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, message, Modal, Select, Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Input from "antd/es/input/Input";
import api from '../Ultils/Api';
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const { Option } = Select;

const ROLE_DISPLAY = {
    'dev': 'Developer',
    'guest': 'Guest',
    'tester': 'Tester',
    'owner': 'Owner'
};

const VALID_ROLES = ['dev', 'guest', 'tester'];

function Members() {
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [form] = Form.useForm();

    // Modal states
    const [isAddMemberModalVisible, setIsAddMemberModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isViewRoleModalVisible, setIsViewRoleModalVisible] = useState(false);

    // States for member management
    const [memberToDelete, setMemberToDelete] = useState(null);
    const [selectedMember, setSelectedMember] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);

    // Router hooks
    const { projectName } = useParams();
    const navigate = useNavigate();

    // Initial data load
    useEffect(() => {
        const token = Cookies.get("token");
        if (token) {
            const decoded = jwtDecode(token);
            setUsername(decoded.sub);
        }
    }, []);

    useEffect(() => {
        if (projectName) {
            fetchMembers();
        }
    }, [projectName]);

    // Check if current user is owner
    const isCurrentUserOwner = () => {
        return true; // Tạm thời để test
        // return members.some(member =>
        //     member.username === username &&
        //     member.role.toLowerCase() === 'owner'
        // );
    };

    // Check if member is owner
    const isMemberOwner = (role) => {
        return role.toLowerCase() === 'owner';
    };

    // API Functions
    const fetchMembers = async () => {
        setLoading(true);
        try {
            const response = await api.get(`/projects/${projectName}/members`);
            if (response.data?.status === 200 && response.data?.data) {
                const processedMembers = response.data.data.map((member, index) => ({
                    key: index,
                    userId: member.userId,
                    username: member.username,
                    role: member.role,
                    addedAt: member.addedAt
                }));
                setMembers(processedMembers);
            }
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const addMember = async () => {
        try {
            const values = await form.validateFields();

            const response = await api.post(`/projects/${projectName}/members`, {
                username: values.username,
                role: values.role
            });

            if (response.data?.status === 200) {
                message.success('Member added successfully');
                setIsAddMemberModalVisible(false);
                form.resetFields();
                fetchMembers();
            }
        } catch (error) {
            handleError(error);
        }
    };

    const updateMemberRole = async () => {
        if (!selectedMember || !selectedRole) return;

        try {
            const response = await api.put(
                `/projects/${projectName}/members/${selectedMember.userId}/role`,
                { role: selectedRole }
            );

            if (response.data?.status === 200) {
                message.success('Role updated successfully');
                setIsViewRoleModalVisible(false);
                fetchMembers();
            }
        } catch (error) {
            handleError(error);
        }
    };

    const deleteMember = async () => {
        if (!memberToDelete) return;

        try {
            const response = await api.delete(
                `/projects/${projectName}/members/${memberToDelete.userId}`
            );

            if (response.data?.status === 200) {
                message.success('Member removed successfully');
                setIsDeleteModalVisible(false);
                fetchMembers();
            }
        } catch (error) {
            handleError(error);
        }
    };

    // Error Handler
    const handleError = (error) => {
        console.error('API Error:', error);
        if (error.response?.status === 401) {
            message.error('Session expired. Please login again.');
            navigate('/login');
        } else if (error.response?.status === 403) {
            message.error('Permission denied.');
        } else {
            message.error(error.response?.data?.message || 'Operation failed');
        }
    };

    // Table configuration
    const columns = [
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username'
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render: (role, record) => (
                <span
                    className="role-link"
                    onClick={() => handleRoleClick(record)}
                    style={{
                        color: isCurrentUserOwner() && role.toLowerCase() !== 'owner' ? '#1890ff' : 'inherit',
                        cursor: isCurrentUserOwner() && role.toLowerCase() !== 'owner' ? 'pointer' : 'default'
                    }}
                >
                    {ROLE_DISPLAY[role.toLowerCase()] || role}
                </span>
            )
        },
        {
            title: 'Added Date',
            dataIndex: 'addedAt',
            key: 'addedAt',
            render: (date) => new Date(date).toLocaleDateString()
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                console.log('Record role:', record.role, 'Current username:', username);
                // Không hiển thị nút xóa nếu:
                // 1. Member là owner
                // 2. Member là chính người dùng hiện tại
                if (isMemberOwner(record.role) || record.username === username) {
                    return null;
                }
                // Hiển thị nút xóa nếu user hiện tại là owner
                if (isCurrentUserOwner()) {
                    return (
                        <Button
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => showDeleteModal(record)}
                        />
                    );
                }
                return null;
            }
        }
    ];

    // UI Handlers
    const showAddModal = () => {
        if (!isCurrentUserOwner()) {
            message.error('Only project owner can add members');
            return;
        }
        setIsAddMemberModalVisible(true);
    };

    const showDeleteModal = (member) => {
        setMemberToDelete(member);
        setIsDeleteModalVisible(true);
    };

    const handleRoleClick = (member) => {
        if (!isCurrentUserOwner() || member.role.toLowerCase() === 'owner') {
            return;
        }
        setSelectedMember(member);
        setSelectedRole(member.role);
        setIsViewRoleModalVisible(true);
    };

    return (
        <LayoutComponentDetail>
            <div className="content-title">
                <h1>Members of Project '{projectName}'</h1>
            </div>

            <div className="member-container">
                {/* Action Bar */}
                <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '20px' }}>
                    {isCurrentUserOwner() && (
                        <Button
                            onClick={showAddModal}
                            type="primary"
                            className="custom-ok-button"
                        >
                            Add Member
                        </Button>
                    )}
                </div>

                {/* Members Table */}
                <div className="member-list">
                    <Table
                        columns={columns}
                        dataSource={members}
                        loading={loading}
                    />
                </div>

                {/* Add Member Modal */}
                <Modal
                    title="Add Member"
                    open={isAddMemberModalVisible}
                    onOk={addMember}
                    onCancel={() => {
                        setIsAddMemberModalVisible(false);
                        form.resetFields();
                    }}
                    okText="Add"
                    cancelText="Cancel"
                    okButtonProps={{ className: 'custom-ok-button' }}
                >
                    <Form form={form} layout="vertical">
                        <Form.Item
                            label="username"
                            name="username"
                            rules={[{ required: true, message: 'Please input username' }]}
                        >
                            <Input placeholder="Username" type="Text" />
                        </Form.Item>
                        <Form.Item
                            label="Role"
                            name="role"
                            initialValue="guest"
                        >
                            <Select>
                                <Option value="guest">{ROLE_DISPLAY['guest']}</Option>
                                <Option value="dev">{ROLE_DISPLAY['dev']}</Option>
                                <Option value="tester">{ROLE_DISPLAY['tester']}</Option>
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>

                {/* Delete Confirmation Modal */}
                <Modal
                    title="Confirm Delete"
                    open={isDeleteModalVisible}
                    onOk={deleteMember}
                    onCancel={() => setIsDeleteModalVisible(false)}
                    okText="Delete"
                    cancelText="Cancel"
                    okButtonProps={{ danger: true }}
                >
                    {memberToDelete && (
                        <p>Are you sure you want to remove <strong>{memberToDelete.username}</strong> from this project?</p>
                    )}
                </Modal>

                {/* Role Update Modal */}
                <Modal
                    title="Update Role"
                    open={isViewRoleModalVisible}
                    onOk={updateMemberRole}
                    onCancel={() => setIsViewRoleModalVisible(false)}
                    okText="Update"
                    cancelText="Cancel"
                    okButtonProps={{ className: 'custom-ok-button' }}
                >
                    {selectedMember && (
                        <Form layout="vertical">
                            <Form.Item label="Member">ls

                                <Input value={selectedMember.username} disabled />
                            </Form.Item>
                            <Form.Item label="Role">
                                <Select value={selectedRole} onChange={setSelectedRole}>
                                    <Option value="guest">{ROLE_DISPLAY['guest']}</Option>
                                    <Option value="dev">{ROLE_DISPLAY['dev']}</Option>
                                    <Option value="tester">{ROLE_DISPLAY['tester']}</Option>
                                </Select>
                            </Form.Item>
                        </Form>
                    )}
                </Modal>
            </div>
        </LayoutComponentDetail>
    );
}

export default Members;
import React, { useState, useEffect } from "react";
import { Form, Input, Button, Card, List, Modal, message, Tag, Tabs } from "antd";
import { FileTextOutlined, DeleteOutlined, EyeOutlined, EditOutlined } from "@ant-design/icons";
import api from "../Ultils/Api";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

const { TabPane } = Tabs;

function DefectSolution({ defectId, projectName, repositoryName }) {
    // State Management
    const [solutions, setSolutions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [viewModalVisible, setViewModalVisible] = useState(false);
    const [updateModalVisible, setUpdateModalVisible] = useState(false);
    const [selectedSolution, setSelectedSolution] = useState(null);
    const [isOwner, setIsOwner] = useState(false);
    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const [userRole, setUserRole] = useState('');

    // Effects
    useEffect(() => {
        loadSolutions();
        checkUserRole();
    }, [defectId, repositoryName, projectName]); // Thêm projectName vào đây

    // API Functions
    const loadSolutions = async () => {
        try {
            setLoading(true);
            const response = await api.get(`/solutions/defect/${defectId}`);
            if (response?.data) {
                setSolutions(response.data);
            }
        } catch (err) {
            handleApiError(err);
        } finally {
            setLoading(false);
        }
    };

    const checkUserRole = async () => {
        try {
            const token = Cookies.get("token");
            if (!token) {
                console.error('Token not found');
                return;
            }

            const decoded = jwtDecode(token);
            const username = decoded.sub;

            const roleResponse = await api.get(`/projects/${projectName}/members/role`, {
                params: { username }
            });

            if (roleResponse.data?.data) {
                const { owner, memberRole } = roleResponse.data.data;
                setIsOwner(owner);
                console.log(roleResponse.data);
                setUserRole(owner ? 'owner' : memberRole);
            }
        } catch (error) {
            handleApiError(error);
        }
    };

    const handleAddSolution = async (values) => {
        try {
            setLoading(true);
            const solutionData = {
                defectId: Number(defectId),
                rootCause: values.rootCause,
                solutionText: values.solutionText,
                stepsToFix: values.stepsToFix,
                // status: "PROPOSED"
            };

            const response = await api.post(`/solutions/defect/${defectId}`, solutionData);
            if (response?.data) {
                message.success('Solution proposed successfully');
                form.resetFields();
                await loadSolutions();
            }
        } catch (err) {
            handleApiError(err);
        } finally {
            setLoading(false);
        }
    };

    const handleUpdateSolution = async (values) => {
        try {
            if (selectedSolution.status !== 'PROPOSED') {
                message.error('Only proposed solutions can be updated');
                return;
            }

            setLoading(true);
            const solutionData = {
                rootCause: values.rootCause,
                solutionText: values.solutionText,
                stepsToFix: values.stepsToFix
            };

            const response = await api.put(`/solutions/${selectedSolution.id}`, solutionData);
            if (response?.data) {
                message.success('Solution updated successfully');
                setUpdateModalVisible(false);
                updateForm.resetFields();
                await loadSolutions();
            }
        } catch (err) {
            handleApiError(err);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (solutionId) => {
        try {
            await api.delete(`/solutions/${solutionId}`);
            message.success('Solution deleted successfully');
            await loadSolutions();
        } catch (err) {
            handleApiError(err);
        }
    };

    const handleVerify = async (solutionId) => {
        try {
            const response = await api.put(`/solutions/${solutionId}/verify`);
            if (response?.data) {
                message.success('Solution verified successfully');
                await loadSolutions();
            }
        } catch (err) {
            handleApiError(err);
        }
    };

    const handleReject = async (solutionId) => {
        try {
            const response = await api.put(`/solutions/${solutionId}/reject`);
            if (response?.data) {
                message.success('Solution rejected successfully');
                await loadSolutions();
            }
        } catch (err) {
            handleApiError(err);
        }
    };

    const handleMarkImplemented = async (solutionId) => {
        try {
            const response = await api.put(`/solutions/${solutionId}`, {
                status: "IMPLEMENTED"
            });
            if (response?.data) {
                message.success('Solution marked as implemented successfully');
                await loadSolutions();
            }
        } catch (err) {
            handleApiError(err);
        }
    };

    // UI Helper Functions
    const handleApiError = (error) => {
        const errorMessage = error.response?.data?.message || 'An error occurred';
        message.error(errorMessage);
        console.error('API Error:', error);
    };

    const showUpdateModal = (solution) => {
        if (solution.status !== 'PROPOSED') {
            message.warning('Only proposed solutions can be updated');
            return;
        }
        setSelectedSolution(solution);
        updateForm.setFieldsValue({
            rootCause: solution.rootCause,
            solutionText: solution.solutionText,
            stepsToFix: solution.stepsToFix
        });
        setUpdateModalVisible(true);
    };

    const showViewModal = (solution) => {
        setSelectedSolution(solution);
        setViewModalVisible(true);
    };

    const getStatusColor = (status) => {
        const statusColors = {
            'PROPOSED': 'blue',
            'VERIFIED': 'green',
            'REJECTED': 'red'
        };
        return statusColors[status] || 'default';
    };

    const canDelete = (solution) => {
        return solution.status === 'PROPOSED';
    };

    // UI Components
    const SolutionForm = () => (
        <Card className="solution-form-card mb-4">
            <div className="solution-form-header mb-4">
                <div className="flex items-center gap-2">
                    <FileTextOutlined className="text-xl" />
                    <h2 className="text-lg font-semibold m-0">Add New Solution</h2>
                </div>
            </div>

            <Form
                form={form}
                layout="vertical"
                onFinish={handleAddSolution}
                className="solution-form"
            >
                <Form.Item
                    name="rootCause"
                    label="Root Cause Analysis"
                    rules={[{ required: true, message: 'Please enter root cause analysis' }]}
                >
                    <Input.TextArea rows={4} placeholder="Describe the root cause..." />
                </Form.Item>

                <Form.Item
                    name="solutionText"
                    label="Solution Description"
                    rules={[{ required: true, message: 'Please enter solution description' }]}
                >
                    <Input.TextArea rows={4} placeholder="Describe your solution..." />
                </Form.Item>

                <Form.Item
                    name="stepsToFix"
                    label="Steps to Fix"
                    rules={[{ required: true, message: 'Please enter steps to fix' }]}
                >
                    <Input.TextArea rows={4} placeholder="List the steps to implement the solution..." />
                </Form.Item>

                <Form.Item>
                    <Button
                        onClick={() => form.submit()}
                        loading={loading}
                        className="custom-ok-button"
                    >
                        Add Solution
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );

    const SolutionsList = ({ dataSource }) => (
        <List
            className="solutions-list"
            loading={loading}
            dataSource={dataSource}
            renderItem={(solution) => (
                <List.Item
                    key={solution.id}
                    actions={[
                        <Button
                            key="view"
                            icon={<EyeOutlined />}
                            onClick={() => showViewModal(solution)}
                        >
                            View
                        </Button>,
                        solution.status === 'PROPOSED' && (
                            <Button
                                key="edit"
                                icon={<EditOutlined />}
                                onClick={() => showUpdateModal(solution)}
                            >
                                Update
                            </Button>
                        ),
                        isOwner && solution.status === 'PROPOSED' && (
                            <Button
                                key="verify"
                                type="primary"
                                onClick={() => {
                                    Modal.confirm({
                                        title: 'Verify Solution',
                                        content: 'Are you sure you want to verify this solution?',
                                        okText: 'Yes',
                                        cancelText: 'No',
                                        onOk: () => handleVerify(solution.id)
                                    });
                                }}
                            >
                                Verify
                            </Button>
                        ),
                        isOwner && solution.status === 'PROPOSED' && (
                            <Button
                                key="reject"
                                danger
                                onClick={() => {
                                    Modal.confirm({
                                        title: 'Reject Solution',
                                        content: 'Are you sure you want to reject this solution?',
                                        okText: 'Yes',
                                        okType: 'danger',
                                        cancelText: 'No',
                                        onOk: () => handleReject(solution.id)
                                    });
                                }}
                            >
                                Reject
                            </Button>
                        ),
                        canDelete(solution) && (
                            <Button
                                key="delete"
                                icon={<DeleteOutlined />}
                                danger
                                onClick={() => {
                                    Modal.confirm({
                                        title: 'Delete Solution',
                                        content: 'Are you sure you want to delete this solution?',
                                        okText: 'Yes',
                                        okType: 'danger',
                                        cancelText: 'No',
                                        onOk: () => handleDelete(solution.id)
                                    });
                                }}
                            >
                                Delete
                            </Button>
                        )
                    ].filter(Boolean)}
                >
                    <List.Item.Meta
                        title={
                            <div className="flex items-center gap-2">
                                <span>Solution #{solution.id}</span>
                                <Tag color={getStatusColor(solution.status)}>
                                    {solution.status}
                                </Tag>
                            </div>
                        }
                        description={
                            <div className="space-y-2">
                                <p><strong>Created by:</strong> {solution.implementedBy}</p>
                                <p><strong>Created at:</strong> {new Date(solution.createdAt).toLocaleString()}</p>
                                <p><strong>Root Cause:</strong> {solution.rootCause}</p>
                            </div>
                        }
                    />
                </List.Item>
            )}
        />
    );

    const ViewModal = () => (
        <Modal
            title={`Solution Details #${selectedSolution?.id}`}
            open={viewModalVisible}
            onCancel={() => setViewModalVisible(false)}
            footer={null}
            width={800}
        >
            {selectedSolution && (
                <div className="space-y-6">
                    <div className="solution-detail-section">
                        <h3 className="font-semibold mb-2">Root Cause</h3>
                        <p>{selectedSolution.rootCause}</p>
                    </div>
                    <div className="solution-detail-section">
                        <h3 className="font-semibold mb-2">Solution</h3>
                        <p>{selectedSolution.solutionText}</p>
                    </div>
                    <div className="solution-detail-section">
                        <h3 className="font-semibold mb-2">Steps to Fix</h3>
                        <p>{selectedSolution.stepsToFix}</p>
                    </div>
                    <div className="solution-meta space-y-2">
                        <p><strong>Created by:</strong> {selectedSolution.implementedBy}</p>
                        <p><strong>Created at:</strong> {new Date(selectedSolution.createdAt).toLocaleString()}</p>
                        <p>
                            <strong>Status:</strong>
                            <Tag color={getStatusColor(selectedSolution.status)} className="ml-2">
                                {selectedSolution.status}
                            </Tag>
                        </p>
                        {selectedSolution.verifiedBy && (
                            <p><strong>Verified by:</strong> {selectedSolution.verifiedBy}</p>
                        )}
                    </div>
                </div>
            )}
        </Modal>
    );

    const UpdateModal = () => (
        <Modal
            title="Update Solution"
            open={updateModalVisible}
            onCancel={() => setUpdateModalVisible(false)}
            footer={null}
            width={800}
        >
            <Form
                form={updateForm}
                layout="vertical"
                onFinish={handleUpdateSolution}
                className="solution-form"
            >
                <Form.Item
                    name="rootCause"
                    label="Root Cause Analysis"
                    rules={[{ required: true, message: 'Please enter root cause analysis' }]}
                >
                    <Input.TextArea rows={4} placeholder="Describe the root cause..." />
                </Form.Item>

                <Form.Item
                    name="solutionText"
                    label="Solution Description"
                    rules={[{ required: true, message: 'Please enter solution description' }]}
                >
                    <Input.TextArea rows={4} placeholder="Describe your solution..." />
                </Form.Item>

                <Form.Item
                    name="stepsToFix"
                    label="Steps to Fix"
                    rules={[{ required: true, message: 'Please enter steps to fix' }]}
                >
                    <Input.TextArea rows={4} placeholder="List the steps to implement the solution..." />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" onClick={() => updateForm.submit()} loading={loading} className={'custom-ok-button'}>
                        Update Solution
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );

    return (
        <div className="defect-solution-container space-y-4">
            <Tabs defaultActiveKey="all">
                <TabPane tab="All Solutions" key="all">
                    <SolutionsList dataSource={solutions} />
                </TabPane>
                {isOwner && (
                    <TabPane tab="Pending Review" key="pending">
                        <SolutionsList
                            dataSource={solutions.filter(s => s.status === 'PROPOSED')}
                        />
                    </TabPane>
                )}
            </Tabs>
            <SolutionForm />
            <ViewModal />
            <UpdateModal />
        </div>
    );
}

export default DefectSolution;
// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from "js-cookie";
import { useAuth } from "./AuthConext";
import { jwtDecode } from "jwt-decode";

const ProtectedRoute = ({ element: Component, componentProps }) => {
    const { isLoggedIn, logout } = useAuth();
    const token = Cookies.get('token');

    if (!token || !isLoggedIn) {
        return <Navigate to="/login" />;
    }

    try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decoded.exp && decoded.exp < currentTime) {
            Cookies.remove('token');
            logout();
            return <Navigate to="/login" />;
        }
    } catch (error) {
        console.error('Error decoding token:', error);
        Cookies.remove('token');
        logout();
        return <Navigate to="/login" />;
    }

    return <Component {...componentProps} />;
};


export default ProtectedRoute;
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Checkbox, Form, message } from "antd";
import Input from "antd/es/input/Input";
import axios from 'axios';
import '../Assets/css/auth.css';
import Cookies from "js-cookie";
import { useAuth } from "./AuthConext";

function Login() {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { login } = useAuth();

    // Load saved credentials on component mount
    useEffect(() => {
        const rememberedUser = localStorage.getItem('rememberedUser');
        if (rememberedUser) {
            const { host,username, password } = JSON.parse(rememberedUser);
            form.setFieldsValue({
                host,
                username,
                password,
                remember: true
            });
        }
    }, [form]);

    const handleRememberMe = (values) => {
        if (values.remember) {
            // Save credentials to localStorage if remember is checked
            localStorage.setItem('rememberedUser', JSON.stringify({
                hostname: values.host,
                username: values.username,
                password: values.password
            }));
        } else {
            // Remove saved credentials if remember is unchecked
            localStorage.removeItem('rememberedUser');
        }
    };

    const onFinish = async (values) => {
        setLoading(true);
        try {
            const response = await axios.post('https://dxgitauto.xyz/api/v1/auth/login', {
                username: values.username,
                password: values.password
            });

            const { message: loginMessage, token } = response.data;

            if (!token) {
                throw new Error('Token not provided');
            }

            // Handle remember me
            handleRememberMe(values);

            // Set token and login
            Cookies.set('token', token);
            login(token);

            message.success(loginMessage || 'Login successful!');

            setTimeout(() => {
                navigate(`/`);
            }, 1500);
        } catch (error) {
            console.error('Login error:', error);
            if (error.response?.data?.message) {
                message.error(error.response.data.message);
            } else {
                message.error('Login failed. Please check your credentials and try again.');
            }
            // If login fails, don't save credentials even if remember is checked
            localStorage.removeItem('rememberedUser');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={'login-container'}>
            <Card className={'login-card'}>
                <h1 className={'login-title'}>Login</h1>
                <Form
                    form={form}
                    name={'login'}
                    layout={'vertical'}
                    onFinish={onFinish}
                    initialValues={{ remember: false }}
                >
                    <Form.Item
                        label={'Username'}
                        name={'username'}
                        rules={[
                            { required: true, message: 'Please input your username' },
                            { min: 4, message: 'Username is too short' },
                            { max: 30, message: 'Username is too long' },
                        ]}
                        validateTrigger={'onBlur'}
                    >
                        <Input placeholder={'Enter your username'} />
                    </Form.Item>
                    <Form.Item
                        label={'Password'}
                        name={'password'}
                        rules={[
                            { required: true, message: 'Please input your password' },
                            { min: 6, message: 'Password is too short' },
                            { max: 30, message: 'Password is too long' },
                        ]}
                        validateTrigger={'onBlur'}
                    >
                        <Input.Password placeholder={'Enter your password'} />
                    </Form.Item>
                    <Form.Item name={'remember'} valuePropName={'checked'}>
                        <div className={'login-options'}>
                            <Checkbox>Remember me</Checkbox>
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type={"primary"}
                            htmlType={'submit'}
                            loading={loading}
                            className={'login-button'}
                        >
                            Login
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
}

export default Login;
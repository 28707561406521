import React, { useState, useEffect } from "react";
import LayoutComponent from "../Layout/LayoutComponent";
import { Button, Col, Form, Input, Modal, Row, Select, Table, message } from "antd";
import Search from "antd/es/input/Search";
import { DeleteOutlined, SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from '../Ultils/Api';

function Users() {
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortField, setSortField] = useState('username');
    const [searchTerm, setSearchTerm] = useState('');
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisibleDeleteUser, setIsModalVisibleDeleteUser] = useState(false);
    const [isModalVisibleCreateUser, setIsModalVisibleCreateUser] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [newAccountName, setNewAccountName] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const { Option } = Select;
    const navigate = useNavigate();

    // Fetch users data
    const fetchUsers = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get('/root/list-users', {
                headers: {
                    'Authorization': token
                }
            });

            // Updated to match the actual data structure from the API
            const formattedData = response.data.data.map(user => ({
                key: user.id.toString(),  // Using id as the key
                username: user.username,
                email: user.email || '-',
                real_name: user.realName || '-',  // Changed to match the API's realName field
                status: user.status,
                created_at: user.createdAt
            }));

            setUserData(formattedData);
        } catch (error) {
            message.error('Failed to fetch users: ' + (error.response?.data?.message || error.message));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const columns = [
        {
            title: 'Account Name',
            dataIndex: 'username',
            key: 'username',
            render: (text, record) => (
                <a onClick={() => handleUserClick(text)}>{text}</a>
            )
        },
        {
            title: 'User Name',
            dataIndex: 'real_name',
            key: 'real_name'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => new Date(text).toLocaleDateString()
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Button onClick={() => handleShowDeleteUserModal(record)} danger>
                    <DeleteOutlined />
                </Button>
            )
        }
    ];

    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    const handleSortChange = (value) => {
        setSortField(value);
    };

    const toggleSortOrder = () => {
        setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
    };

    // Filter and sort data
    const getFilteredAndSortedData = () => {
        let filteredData = [...userData];

        if (searchTerm) {
            filteredData = filteredData.filter(user =>
                user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
                (user.real_name && user.real_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (user.email && user.email.toLowerCase().includes(searchTerm.toLowerCase()))
            );
        }

        filteredData.sort((a, b) => {
            let compareA = a[sortField]?.toLowerCase() || '';
            let compareB = b[sortField]?.toLowerCase() || '';

            if (sortOrder === 'asc') {
                return compareA.localeCompare(compareB);
            }
            return compareB.localeCompare(compareA);
        });

        return filteredData;
    };

    // CREATE USER - Updated to use new endpoint
    const handleCreateUser = async () => {
        try {
            const token = localStorage.getItem('token');
            await axios.post('/root/add-user', {
                username: newAccountName,
                email: newEmail,
                password: newPassword
            }, {
                headers: {
                    'Authorization': token
                }
            });

            message.success('User created successfully');
            setIsModalVisibleCreateUser(false);
            setNewAccountName('');
            setNewEmail('');
            setNewPassword('');
            fetchUsers();
        } catch (error) {
            message.error('Failed to create user: ' + (error.response?.data?.message || error.message));
        }
    };

    // DELETE USER - Updated to use new endpoint
    const handleShowDeleteUserModal = (record) => {
        setUserToDelete(record);
        setIsModalVisibleDeleteUser(true);
    };

    const handleCancelDeleteUserModal = () => {
        setIsModalVisibleDeleteUser(false);
        setUserToDelete(null);
    };

    const handleDeleteUser = async () => {
        if (!userToDelete) return;

        try {
            const token = localStorage.getItem('token');
            await axios.delete(`/root/delete/${userToDelete.username}`, {
                headers: {
                    'Authorization': token,
                    'username': userToDelete.username
                }
            });

            message.success('User deleted successfully');
            handleCancelDeleteUserModal();
            fetchUsers();
        } catch (error) {
            message.error('Failed to delete user: ' + (error.response?.data?.message || error.message));
        }
    };

    const handleUserClick = (username) => {
        navigate(`/user/${username}`);
    };

    return (
        <LayoutComponent>
            <div className="content-title">
                <h1>Manage Users</h1>
            </div>


            <Row gutter={16} className="search-sort-container">
                <Col span={18}>
                    <Search
                        placeholder="Search by username, real name, or email"
                        allowClear
                        onSearch={handleSearch}
                        style={{width: '98%', marginLeft: '20px'}}
                    />
                </Col>
                <Col span={4}>
                    <Select
                        defaultValue="username"
                        style={{width: '100%'}}
                        onChange={handleSortChange}
                    >
                        <Option value="username">Account Name</Option>
                        <Option value="real_name">User Name</Option>
                        <Option value="email">Email</Option>
                    </Select>
                </Col>
                <Col span={2}>
                    <Button
                        type="text"
                        icon={sortOrder === 'asc' ? <SortAscendingOutlined/> : <SortDescendingOutlined/>}
                        className="sort-btn"
                        onClick={toggleSortOrder}
                        style={{width: '83%'}}
                    />
                </Col>
            </Row>

            <div className="btn-create-user" style={{marginLeft: '20px', marginBottom: '16px'}}>
                <Button className={'custom-ok-button'} onClick={() => setIsModalVisibleCreateUser(true)}>
                    Create User
                </Button>
            </div>

            <div className="user-list" style={{margin: '24px 20px'}}>
                <Table
                    columns={columns}
                    dataSource={getFilteredAndSortedData()}
                    loading={loading}
                    pagination={{
                        pageSize: 10,
                        showSizeChanger: true,
                        showTotal: (total) => `Total ${total} users`
                    }}
                />
            </div>

            <Modal
                title="Delete account. Are you ABSOLUTELY SURE?"
                open={isModalVisibleDeleteUser}
                onOk={handleDeleteUser}
                onCancel={handleCancelDeleteUserModal}
                okText="Delete"
                cancelText="Cancel"
                okButtonProps={{
                    className: 'custom-ok-button',
                    danger: true
                }}
            >
                {userToDelete && (
                    <p>Are you sure you want to delete user <strong>{userToDelete.username}</strong>?</p>
                )}
            </Modal>

            <Modal
                title="Create User"
                open={isModalVisibleCreateUser}
                onOk={handleCreateUser}
                onCancel={() => {
                    setIsModalVisibleCreateUser(false);
                    setNewAccountName('');
                    setNewEmail('');
                    setNewPassword('');
                }}
                okText="Create"
                cancelText="Cancel"
            >
                <Form layout="vertical">
                    <Form.Item
                        label="Account Name"
                        name="accountName"
                        rules={[{ required: true, message: 'Please input account name' }]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder="Account Name"
                            value={newAccountName}
                            onChange={(e) => setNewAccountName(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            { required: true, message: 'Please input email' },
                            {
                                type: 'email',
                                message: 'Please enter a valid email address'
                            },
                            {
                                pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: 'Please enter a valid email format (e.g., example@domain.com)'
                            }
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder="Email"
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{required: true, message: 'Please input password'}]}
                        validateTrigger="onBlur"
                    >
                        <Input.Password
                            placeholder="Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </LayoutComponent>
    );
}

export default Users;
import React, { useState, useEffect } from 'react';
import { Card, Select, message } from 'antd';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import api from '../Ultils/Api';
import { useNavigate } from 'react-router-dom';

const UserChart = () => {
    const [selectedYear, setSelectedYear] = useState('all');  // Changed default to 'all'
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState({});
    const [continuousData, setContinuousData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserData();
    }, []);

    const fetchUserData = async () => {
        try {
            setLoading(true);
            const response = await api.get('/statistics/users/monthly');

            if (response.data?.status === 200) {
                // Transform API data into the required format
                const dataByYear = {};

                response.data.data.forEach(item => {
                    const [year, month] = item.month.split('-');
                    if (!dataByYear[year]) {
                        dataByYear[year] = [];
                    }

                    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                    const monthIndex = parseInt(month) - 1;

                    dataByYear[year].push({
                        month: monthNames[monthIndex],
                        monthIndex: monthIndex,
                        year: year,
                        users: item.totalUsers,
                        newUsers: item.newUsers,
                        fullMonth: `${monthNames[monthIndex]} ${year}`
                    });
                });

                // Sort data within each year by month
                Object.keys(dataByYear).forEach(year => {
                    dataByYear[year].sort((a, b) => a.monthIndex - b.monthIndex);
                });

                setUserData(dataByYear);

                // Create continuous data array
                const allData = [];
                Object.keys(dataByYear).sort().forEach(year => {
                    allData.push(...dataByYear[year]);
                });
                setContinuousData(allData);

                // Removed the auto-selection of the most recent year since we want 'all' by default
            }
        } catch (error) {
            if (error.response?.status === 401) {
                message.error("Please log in again");
                navigate('/login');
            } else {
                message.error("Failed to fetch user data");
            }
        } finally {
            setLoading(false);
        }
    };

    const years = Object.keys(userData).sort();

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;
            return (
                <div style={{
                    backgroundColor: 'white',
                    padding: '10px',
                    border: '1px solid #ccc',
                    borderRadius: '4px'
                }}>
                    <p style={{ margin: 0 }}>{`${data.fullMonth}: ${data.users.toLocaleString()} users`}</p>
                </div>
            );
        }
        return null;
    };

    const getDisplayData = () => {
        if (selectedYear === 'all') {
            return continuousData;
        }
        return userData[selectedYear] || [];
    };

    return (
        <Card
            style={{ marginTop: 24 }}
            loading={loading}
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>User Line Chart</span>
                    <Select
                        value={selectedYear}
                        style={{ width: 120 }}
                        onChange={setSelectedYear}
                        options={[
                            { value: 'all', label: 'All Years' },
                            ...years.map(year => ({
                                value: year,
                                label: year
                            }))
                        ]}
                    />
                </div>
            }
        >
            <div style={{ height: 400 }}>
                <ResponsiveContainer>
                    <LineChart
                        data={getDisplayData()}
                        margin={{
                            top: 20,
                            right: 20,
                            left: 20,
                            bottom: 20,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="fullMonth"
                            tickCount={12}
                            tick={{ fontSize: 12 }}
                            interval="preserveStartEnd"
                        />
                        <YAxis
                            tickFormatter={(value) => `${(value / 1000).toFixed(1)}k`}
                            tick={{ fontSize: 12 }}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Line
                            type="monotone"
                            dataKey="users"
                            stroke="#1890ff"
                            strokeWidth={3}
                            dot={{
                                r: 5,
                                fill: "white",
                                stroke: "#ec4b0f",
                                strokeWidth: 2
                            }}
                            connectNulls
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </Card>
    );
};

export default UserChart;